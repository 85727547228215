export const SUBJECT_DETAIL_CONFIG_REPORT = {
  math: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description:
        "คุณมีความสามารถด้านคณิตศาสตร์ใน 'ระดับที่ดี' มีความเข้าใจและคำนวนเลขในหัวข้อ การบวกลบคูณหารระคน คำนวนกำไรขาดทุนลดราคา เข้าใจการคำนวนเปอร์เซ็นต์ร้อยละ สามารถแก้โจทย์ปัญหาเศษส่วนได้",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description:
        "คุณมีความสามารถด้านคณิตศาสตร์ใน 'ระดับปานกลาง' มีความเข้าใจและคำนวนเลขในหัวข้อ การบวกลบคูณหารระคน คำนวนกำไรขาดทุนลดราคา เข้าใจการคำนวนเปอร์เซ็นต์ร้อยละ สามารถแก้โจทย์ปัญหาเศษส่วนได้",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description:
        "คุณมีความสามารถด้านคณิตศาสตร์ใน 'ระดับแย่' ยังไม่เข้าใจและไม่สามารถคำนวนเลขในหัวข้อ การบวกลบคูณหารระคน คำนวนกำไรขาดทุนลดราคา เข้าใจการคำนวนเปอร์เซ็นต์ร้อยละ สามารถแก้โจทย์ปัญหาเศษส่วนได้ดี",
    },
  ],
  logic: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description:
        "คุณมีความคิดที่เป็นเหตุเป็นผลใน 'ระดับที่ดี' สามารถสรุปความได้ เข้าใจความสัมพันพันธ์ของรูปแบบ รูปประกอบ รูปสองและสามมิติ และความสัมพันธ์ด้านตัวเลขและภาษาได้ดี",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description:
        "คุณมีความคิดที่เป็นเหตุเป็นผล 'ระดับปานกลาง' สามารถสรุปความได้ เข้าใจความสัมพันพันธ์ของรูปแบบ รูปประกอบ รูปสองและสามมิติ และความสัมพันธ์ด้านตัวเลขและภาษาได้บ้าง",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description:
        "คุณมีความคิดที่เป็นเหตุเป็นผล 'ระดับแย่' สามารถในด้านสรุปความยังไม่ดีนัก  เข้าใจความสัมพันพันธ์ของรูปแบบ รูปประกอบ รูปสองและสามมิติ และความสัมพันธ์ด้านตัวเลขและภาษายังไม่มากพอ",
    },
  ],
  english: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description:
        "คุณมีรู้ด้านภาษาอังกฤษ 'ระดับที่ดี' เข้าใจคำศัพท์ การใช้คำศัพท์ รูปแบบการสื่อสาร หลักไวยากรณ์สำหรับการสื่อสารและการทำงาน",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description:
        "คุณมีรู้ด้านภาษาอังกฤษ 'ระดับปานกลาง' เข้าใจคำศัพท์ การใช้คำศัพท์ รูปแบบการสื่อสาร หลักไวยากรณ์สำหรับการสื่อสารและการทำงานในระดับพอใช้ ",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description:
        "คุณมีรู้ด้านภาษาอังกฤษ 'ระดับแย่' ยังไม่เข้าใจคำศัพท์ การใช้คำศัพท์ รูปแบบการสื่อสาร หลักไวยากรณ์สำหรับการสื่อสารและการทำงาน ยังไม่ดีนัก",
    },
  ],
  disc: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description: "...(รอข้อความ)",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description: "...(รอข้อความ)",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description: "...(รอข้อความ)",
    },
  ],
  recommendation: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description:
        "ความรู้และความสามารถโดยรวมอยู่ในระดับสูง ตรงกับความต้องการของบริษัท 'X%' เหมาะสมกับตำแหน่งงานที่บริษัทต้องการ",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description:
        "ความรู้และความสามารถโดยรวมอยู่ในระดับปานกลาง ตรงกับความต้องการของบริษัท 'X%' อาจยังไม่เหมาะสมกับตำแหน่งงานที่บริษัทต้องการ",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description:
        "ความรู้และความสามารถโดยรวมอยู่ในระดับต่ำ ตรงกับความต้องการของบริษัทเพียงแค่ 'X%' ยังไม่เหมาะสมกับตำแหน่งงานที่บริษัทต้องการ",
    },
  ],
  percentile: [
    {
      name: "ดี",
      min_score: 100,
      max_score: 100,
      operation: "",
      description: "ได้คะแนนสูงสุดในรอบนี้",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 99,
      operation: "",
      description: "ความสามารถไม่ตรงตามคุณสมบัติที่ต้องการ...(รอข้อความ)",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 50,
      operation: "",
      description: "ความสามารถไม่ตรงตามคุณสมบัติที่ต้องการ...(รอข้อความ)",
    },
  ],
  matching_score: [
    {
      name: "ดี",
      min_score: 70,
      max_score: 100,
      operation: "",
      description: "...(รอข้อความ)",
    },
    {
      name: "ปานกลาง",
      min_score: 50,
      max_score: 69,
      operation: "",
      description: "...(รอข้อความ)",
    },
    {
      name: "แย่",
      min_score: 0,
      max_score: 49,
      operation: "",
      description: "...(รอข้อความ)",
    },
  ],
};
