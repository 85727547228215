import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import {
  Cached as LoadingIcon,
  CheckCircle as SuccessIcon,
  Warning as ErrorIcon,
} from '@material-ui/icons'

import BaseModal from 'components/modal/BaseModal'
import Button from 'components/form/Button'

const STATUS = {
  loading: 'loading',
  success: 'success',
  error: 'error',
}

const Div = styled.div`
  padding: 20px;
  width: 620px;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    background: #f7f9fa;
    border-radius: 50%;

    svg {
      width: 74px;
      height: 74px;
      color: #f1b418;

      &.success-icon {
        color: #31c788;
      }

      &.error-icon {
        color: #e44c5c;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 32px;
    font-family: 'kelson_sansbold';
    margin-top: 30px;
  }

  .content-detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress-total {
      font-size: 24px;
      font-family: 'kelson_sans';
      margin-top: 24px;
    }

    .progress {
      color: #b6b8c1;
      text-align: center;
      margin-top: 33px;

      .MuiCircularProgress-root {
        color: #b6b8c1;
        margin-bottom: 6px;
      }
    }

    .success-detail {
      margin-top: 20px;
      font-size: 20px;

      .row {
        display: flex;
        margin-top: 2px;

        .label {
          font-weight: bold;
          width: 100px;
        }
      }
    }

    .error-detail {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 34px;
    text-align: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`

const PopupRequestApi = ({ open, onClose, listId = [] }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  let status = STATUS.loading
  if (!isLoading) {
    if (!error) {
      status = STATUS.success
    } else {
      status = STATUS.error
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
      if (Math.random() > 0.5) {
        setError('Please contact support')
      }
    }, 1500)
  }, [])

  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <div className="icon-wrapper">
          {status === STATUS.loading && <LoadingIcon className="loading-icon" />}
          {status === STATUS.success && <SuccessIcon className="success-icon" />}
          {status === STATUS.error && <ErrorIcon className="error-icon" />}
        </div>
        <div className="title">
          {status === STATUS.loading && 'Processing, please wait'}
          {status === STATUS.success && 'Finish'}
          {status === STATUS.error && 'Processing Failed'}
        </div>
        <div className="content-detail">
          {status === STATUS.loading && (
            <>
              {listId.length > 1 && (
                <div className="progress-total">Total {listId.length} Records</div>
              )}
              <div className="progress">
                <CircularProgress size={50} />
                <div>Processing</div>
              </div>
            </>
          )}
          {status === STATUS.success && (
            <div className="success-detail">
              {listId.length === 1 ? (
                'You have successfully completed one record sync.'
              ) : (
                <>
                  <div className="row">
                    <div className="label">Success</div>
                    <div className="value">{listId.length} Record(s)</div>
                  </div>
                  <div className="row">
                    <div className="label">Fail</div>
                    <div className="value">0 Record(s)</div>
                  </div>
                </>
              )}
            </div>
          )}
          {status === STATUS.error && <div className="error-detail">{error}</div>}
        </div>
        <div className="button-wrapper">
          {(status === STATUS.success || status === STATUS.error) && (
            <Button onClick={onClose}>DONE</Button>
          )}
        </div>
      </Div>
    </BaseModal>
  )
}

export default PopupRequestApi
