import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";

import { editPersonalInfo } from "redux/actions/member";

import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
  selectMasterDataNationality,
} from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const PopupEditInformationPersonal = ({
  open,
  onClose,
  maxWidth,
  memberType,
  taxAddressId,
  DocAddressId,
}) => {
  const dispatch = useDispatch();
  const { member_id } = useParams();
  const masterDataProvince = useSelector(selectMasterDataProvince);
  const masterDataDistrict = useSelector(selectMasterDataDistrict);
  const masterDataSubDistrict = useSelector(selectMasterDataSubDistrict);
  const masterDataNationality = useSelector(selectMasterDataNationality);
  const { memberData } = useSelector((state) => state.member);

  const [dataProvinceTax, setDataProvinceTax] = useState(masterDataProvince);
  const [dataDistrictTax, setDataDistrictTax] = useState([]);
  const [dataSubdistrictTax, setDataSubDistrictTax] = useState([]);

  const [dataProvinceDoc, setDataProvinceDoc] = useState(masterDataProvince);
  const [dataDistrictDoc, setDataDistrictDoc] = useState([]);
  const [dataSubdistrictDoc, setDataSubDistrictDoc] = useState([]);

  const [nationality, setNationality] = useState(memberData?.nationality);
  const [idCardNumber, setIdCardNumber] = useState(memberData?.id_card);

  const [taxAddress, setTaxAddress] = useState(
    memberData?.member_address?.[0]?.address
  );
  const [taxRoad, setTaxRoad] = useState(memberData?.member_address?.[0]?.road);
  const [taxProvince, setTaxProvince] = useState();
  const [taxDistrict, setTaxDistrict] = useState();
  const [taxSubdistrict, setTaxSubdistrict] = useState();
  const [taxPostcode, setTaxPostcode] = useState();

  const [documentAddress, setDocumentAddress] = useState(
    memberData?.member_address?.[1]?.address
  );
  const [documentRoad, setDocumentRoad] = useState(
    memberData?.member_address?.[1]?.road
  );
  const [documentProvince, setDocumentProvince] = useState();
  const [documentDistrict, setDocumentDistrict] = useState();
  const [documentSubdistrict, setDocumentSubdistrict] = useState();
  const [documentPostcode, setDocumentPostcode] = useState();

  useEffect(() => {
    if (memberData?.member_address?.[0]?.province_id) {
      handleProvinceChangeTax(memberData?.member_address?.[0]?.province_id);
    }
  }, [memberData?.member_address?.[0]?.province_id]);

  useEffect(() => {
    if (memberData?.member_address?.[0]?.district_id) {
      handleDistrictChangeTax(memberData?.member_address?.[0]?.district_id);
    }
  }, [memberData?.member_address?.[0]?.district_id]);

  useEffect(() => {
    if (memberData?.member_address?.[0]?.sub_district_id) {
      handleSubDistrictChangeTax(
        memberData?.member_address?.[0]?.sub_district_id
      );
    }
  }, [memberData?.member_address?.[0]?.sub_district_id]);

  useEffect(() => {
    if (memberData?.member_address?.[1]?.province_id) {
      handleProvinceChangeDoc(memberData?.member_address?.[1]?.province_id);
    }
  }, [memberData?.member_address?.[1]?.province_id]);

  useEffect(() => {
    if (memberData?.member_address?.[1]?.district_id) {
      handleDistrictChangeDoc(memberData?.member_address?.[1]?.district_id);
    }
  }, [memberData?.member_address?.[1]?.district_id]);

  useEffect(() => {
    if (memberData?.member_address?.[1]?.sub_district_id) {
      handleSubDistrictChangeDoc(
        memberData?.member_address?.[1]?.sub_district_id
      );
    }
  }, [memberData?.member_address?.[1]?.sub_district_id]);

  const handleProvinceChangeTax = (value) => {
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id == value);
    setDataDistrictTax(districts);
    setTaxProvince(value);
  };
  const handleDistrictChangeTax = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id == value);
    setDataSubDistrictTax(subDistricts);
    setTaxDistrict(value);
  };
  const handleSubDistrictChangeTax = (value) => {
    setTaxSubdistrict(value);
    handlePostcodeChangeTax(value);
  };

  const handlePostcodeChangeTax = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value == value)
      .map((z) => z.postcode)[0];
    setTaxPostcode(postcode);
  };

  const handleProvinceChangeDoc = (value) => {
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id == value);
    setDataDistrictDoc(districts);
    setDocumentProvince(value);
  };
  const handleDistrictChangeDoc = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id == value);
    setDataSubDistrictDoc(subDistricts);
    setDocumentDistrict(value);
  };
  const handleSubDistrictChangeDoc = (value) => {
    setDocumentSubdistrict(value);
    handlePostcodeChangeDoc(value);
  };

  const handlePostcodeChangeDoc = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value == value)
      .map((z) => z.postcode)[0];
    setDocumentPostcode(postcode);
  };

  const submitEditMember = () => {
    let obj = {
      id_card: idCardNumber,
      nationality: nationality,
      member_type: memberType,
      address: [
        {
          id: taxAddressId,
          member_id: +member_id,
          address_type: 1,
          address: taxAddress,
          road: taxRoad,
          province_id: taxProvince,
          district_id: taxDistrict,
          sub_district_id: taxSubdistrict,
          zip_code: taxPostcode,
        },
      ],
    };
    if (+member_id === 2) {
      obj.address.push({
        id: DocAddressId,
        member_id: +member_id,
        address_type: 2,
        address: documentAddress,
        road: taxRoad,
        province_id: documentProvince,
        district_id: documentDistrict,
        sub_district_id: documentSubdistrict,
        zip_code: documentPostcode,
      });
    }
    dispatch(editPersonalInfo(+member_id, obj));
    onClose();
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Edit Personal Information</div>

          <p className="form-topic">ข้อมูล</p>
          <InlineSpaceText label="สัญชาติ">
            <Select
              items={masterDataNationality}
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              defaultValue="เลือกสัญชาติ"
              className="field-nationality"
              id="field-nationality"
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสบัตรประชาชน">
            <TextField
              value={idCardNumber}
              onChange={(e) => setIdCardNumber(e.target.value)}
              className="field-idCardNumber"
            />
          </InlineSpaceText>

          <p className="form-topic">ที่อยู่ในการออกใบกำกับภาษี</p>
          <InlineSpaceText label="ที่อยู่ใบกำกับภาษี">
            <TextField
              value={taxAddress}
              onChange={(e) => setTaxAddress(e.target.value)}
              className="field-taxaAddress"
              id="address-taxaAddress"
            />
          </InlineSpaceText>
          <InlineSpaceText label="ถนน">
            <TextField
              value={taxRoad}
              onChange={(e) => setTaxRoad(e.target.value)}
              className="field-taxRoad"
              id="address-taxRoad"
            />
          </InlineSpaceText>
          <InlineSpaceText label="จังหวัด">
            <Select
              items={dataProvinceTax}
              value={taxProvince}
              onChange={(e) => handleProvinceChangeTax(e.target.value)}
              defaultValue="เลือกจังหวัด"
              className="field-taxProvince"
              id="address-taxProvince"
            />
          </InlineSpaceText>
          <InlineSpaceText label="อำเภอ">
            <Select
              items={dataDistrictTax}
              value={taxDistrict}
              onChange={(e) => handleDistrictChangeTax(e.target.value)}
              defaultValue="เลือกอำเภอ"
              className="field-taxDistrict"
              id="address-taxDistrict"
            />
          </InlineSpaceText>
          <InlineSpaceText label="ตำบล">
            <Select
              items={dataSubdistrictTax}
              value={taxSubdistrict}
              onChange={(e) => handleSubDistrictChangeTax(e.target.value)}
              defaultValue="เลือกตำบล"
              className="field-taxSubdistrict"
              id="address-taxSubdistrict"
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสไปรษณีย์">
            <TextField
              value={taxPostcode}
              className="field-taxPostcode"
              id="address-taxPostcode"
            />
          </InlineSpaceText>

          {+member_id === 2 && (
            <>
              <p className="form-topic">ที่อยู่สำหรับจัดส่งเอกสาร</p>
              <InlineSpaceText label="ที่อยู่ส่งเอกสาร">
                <TextField
                  value={documentAddress}
                  onChange={(e) => setDocumentAddress(e.target.value)}
                  className="field-docAddress"
                  id="address-docAddress"
                />
              </InlineSpaceText>
              <InlineSpaceText label="ถนน">
                <TextField
                  value={documentRoad}
                  onChange={(e) => setDocumentRoad(e.target.value)}
                  className="field-docRoad"
                  id="address-docRoad"
                />
              </InlineSpaceText>
              <InlineSpaceText label="จังหวัด">
                <Select
                  items={dataProvinceDoc}
                  value={documentProvince}
                  onChange={(e) => handleProvinceChangeDoc(e.target.value)}
                  defaultValue="เลือกจังหวัด"
                  className="field-docProvince"
                  id="address-docProvince"
                />
              </InlineSpaceText>
              <InlineSpaceText label="อำเภอ">
                <Select
                  items={dataDistrictDoc}
                  value={documentDistrict}
                  onChange={(e) => handleDistrictChangeDoc(e.target.value)}
                  defaultValue="เลือกอำเภอ"
                  className="field-docDistrict"
                  id="address-docDistrict"
                />
              </InlineSpaceText>
              <InlineSpaceText label="ตำบล">
                <Select
                  items={dataSubdistrictDoc}
                  value={documentSubdistrict}
                  onChange={(e) => handleSubDistrictChangeDoc(e.target.value)}
                  defaultValue="เลือกตำบล"
                  className="field-docSubdistrict"
                  id="address-docSubdistrict"
                />
              </InlineSpaceText>
              <InlineSpaceText label="รหัสไปรษณีย์">
                <TextField
                  value={documentPostcode}
                  className="field-docPostcode"
                  id="address-docPostcode"
                />
              </InlineSpaceText>
            </>
          )}
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancer{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitEditMember()}>
            Edit
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditInformationPersonal;
