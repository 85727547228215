import {
  ADD_CUSTOM_QUESTION_STORAGE,
  ADD_INVITE_STORAGE,
  ADD_TEST_EXAM_STORAGE,
  CLEAR_CUSTOM_QUESTION_STORAGE,
  CLEAR_TEST_EXAM_STORAGE,
  CREATE_CAMPAIGN,
  CREATE_NVITE_EXAMER,
  DELETE_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_MANAGEMENT_LIST,
  GET_CUSTOM_QUESTION_LIST,
  GET_TEST_EXAM_LIST,
  PATCH_CAMPAIGN_BY_ID,
} from "redux/actionTypes";
import {} from "utils/common";

const mapCampaignItems = (v) => ({
  id: v.id,
  slug: v.slug,
  campaignName: v.campaign_name,
  campaignStartDate: v.campaign_start_date,
  campaignEndDate: v.campaign_end_date,
  createdAt: v.created_at,
  status: v.status,
  campaignType: v.campaign_type,
  organizationName: v.organization_name,
  organizationId: v.organization_id,
  countInvite: v.count_invite,
  countExamer: v.count_examer,
});

const mapTestExamItems = (v) => ({
  id: v.id,
  code: v.code,
  name: v.name,
  amountQuestion: v.amount_question,
  author: v.author,
  subjectName: v.subject_name,
  createdAt: v.created_at,
  questionType: v.question_type,
  examTime: v.exam_time,
  language: v.language,
  level: v.level,
  isPublished: v.is_published,
  description: v.description,
  examMode: v.exam_mode,
  topicName: v.topic_name,
});

const mapCustomQuestionItems = (v) => ({
  id: v.id,
  questionTitle: v.question_title,
  questionType: v.question_type,
  answerType: v.answer_type,
  questionTime: v.question_time,
  videoUrl: v.video_url,
  imageUrl: v.image_url,
  questionDescription: v.question_description,
  isShuffleAnswer: v.is_shuffle_answer,
  status: v.status,
  companyId: v.company_id,
  isPublished: v.is_published,
  createdAt: v.created_at,
  updatedAt: v.updated_at,
  choice: v.choice,
});

const initialState = {
  total: 0,
  limit: 10,
  skip: 0,
  items: [],
  testExams: [],
  customQuestions: [],
  examsetStorage: [],
  customQuestionStorage: [],
  inviteStorage: [],
  campaignCreateResponse: {},
  campaignDetail: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CAMPAIGN_MANAGEMENT_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_CAMPAIGN_MANAGEMENT_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;

      return {
        ...state,
        total,
        limit,
        skip,
        items: data.map(mapCampaignItems),
      };
    }
    case GET_CAMPAIGN_MANAGEMENT_LIST.FAILURE:
      return {
        ...state,
      };
    case CREATE_CAMPAIGN.REQUEST:
      return {
        ...state,
      };
    case CREATE_CAMPAIGN.SUCCESS: {
      return {
        ...state,
        campaignCreateResponse: action.payload,
      };
    }
    case CREATE_CAMPAIGN.FAILURE:
      return {
        ...state,
      };
    case DELETE_CAMPAIGN_BY_ID.REQUEST:
      return {
        ...state,
      };
    case DELETE_CAMPAIGN_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case DELETE_CAMPAIGN_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_TEST_EXAM_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_TEST_EXAM_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        testExams: data.map(mapTestExamItems),
      };
    }
    case GET_TEST_EXAM_LIST.FAILURE:
      return {
        ...state,
      };
    case ADD_TEST_EXAM_STORAGE:
      console.log(action.payload);
      return {
        ...state,
        examsetStorage: action.payload,
      };
    case ADD_CUSTOM_QUESTION_STORAGE:
      return {
        ...state,
        customQuestionStorage: action.payload,
      };
    case CLEAR_TEST_EXAM_STORAGE:
      return {
        ...state,
        examsetStorage: [],
      };
    case CLEAR_CUSTOM_QUESTION_STORAGE:
      return {
        ...state,
        customQuestionStorage: [],
      };
    case GET_CUSTOM_QUESTION_LIST.REQUEST:
      return {
        ...state,
      };
    case GET_CUSTOM_QUESTION_LIST.SUCCESS: {
      const { total, limit, skip, data } = action.payload;
      return {
        ...state,
        total,
        limit,
        skip,
        customQuestions: data.map(mapCustomQuestionItems),
      };
    }
    case GET_CUSTOM_QUESTION_LIST.FAILURE:
      return {
        ...state,
      };
    case CREATE_NVITE_EXAMER.REQUEST:
      return {
        ...state,
      };
    case CREATE_NVITE_EXAMER.SUCCESS: {
      return {
        ...state,
      };
    }
    case CREATE_NVITE_EXAMER.FAILURE:
      return {
        ...state,
      };
    case GET_CAMPAIGN_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_CAMPAIGN_BY_ID.SUCCESS: {
      return {
        ...state,
        campaignDetail: action.payload,
      };
    }
    case GET_CAMPAIGN_BY_ID.FAILURE:
      return {
        ...state,
      };
    case PATCH_CAMPAIGN_BY_ID.REQUEST:
      return {
        ...state,
      };
    case PATCH_CAMPAIGN_BY_ID.SUCCESS: {
      return {
        ...state,
      };
    }
    case PATCH_CAMPAIGN_BY_ID.FAILURE:
      return {
        ...state,
      };
    case ADD_INVITE_STORAGE: {
      return {
        ...state,
        inviteStorage: action.payload,
      };
    }
    default:
      return state;
  }
};
