import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import TextFieldArea from "components/form/TextFieldArea";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Grid from "@mui/material/Grid";
const Div = styled.div`
  margin-top: 20px;
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  button {
    width: 100%;
  }

  .btn_back {
    margin-right: 20px;
  }

  .conditions-container {
    display: flex;
    padding: 10px 0px;
  }
  .input-status {
    width: 120px;
    margin-right: 15px;
  }
  .input-conditionscore {
    width: 20%;
  }
`;

const ConditionsInterviewForm = ({ formProp }) => {
  return (
    <Div>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <div className="conditions-container">
            <div className="input-status">
              <TextField
                value={formProp?.name || "ครบเงื่อนไข"}
                className="field_skilldetail"
                disabled
              />
            </div>
            <div className="input-conditionscore">
              <TextField
                value={formProp?.description || "ให้เข้าสัมภาษณ์"}
                className="field_skilldetail"
                disabled
              />
            </div>
          </div>
          <div className="conditions-container">
            <div className="input-status">
              <TextField
                value={formProp?.name || "ไม่ครบเงื่อนไข"}
                className="field_skilldetail"
                disabled
              />
            </div>
            <div className="input-conditionscore">
              <TextField
                value={formProp?.description || "ไม่ให้เข้าสัมภาษณ์"}
                className="field_skilldetail"
                disabled
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Div>
  );
};

export default ConditionsInterviewForm;
