import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import { login } from "redux/actions/auth";

import { addInviteStorage } from "redux/actions/campaign";

import {} from "../../redux/selectors";

import * as XLSX from "xlsx";
const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }
`;

const PopupUploadInviteCSV = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isEdit = false,
  isUploading,
  maxWidth,
}) => {
  const dispatch = useDispatch();
  const { inviteStorage } = useSelector(
    (state) => state.campaign
  );
  const [inviteList, setInviteList] = useState([]);

  const handleReadFile = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      var workbook = XLSX.read(evt.target.result, { type: "array" });
      var jsonExam = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );
      let dataInviteList = [];

      jsonExam.forEach((it, index) => {
        if (it.length == 0) return;
        if (index != 0) {
          dataInviteList.push({
            first_name: it[0],
            last_name: it[1],
            email: it[2],
          });
        }
      });
      setInviteList(dataInviteList);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUploadCSV = () => {
    console.log(inviteStorage);
    dispatch(addInviteStorage(inviteList));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="section-group">
            <div className="headline">Invitation by Name List</div>
            <p>Upload multiple email address</p>
            <div className="group-field">
              <TextField type="file" onChange={handleReadFile} />
            </div>
          </div>
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => handleUploadCSV()}>
            Confirm
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupUploadInviteCSV;
