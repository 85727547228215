import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Table from "components/table/Table";

import Grid from "@mui/material/Grid";

import {
  getCustomQuestionList,
  addCustomQuestionStorage,
} from "redux/actions/campaign";
import {} from "../../redux/selectors";

import ReactHtmlParser from "react-html-parser";
import DeltaToHtml from "components/form/DeltaToHtml";

const Div = styled.div`
  padding: 20px;
  width: 1000px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 18px;
  }

  .level_container {
    display: flex;
    margin-bottom: 5px;

    .level_selection {
      margin-right: 5px;
    }
    .level_field {
      margin-right: 5px;
    }
    .level_btn {
      margin-top: 7px;
    }
  }

  .right {
    text-align: right;
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const columns = [
  {
    field: "no",
    headerName: "ลำดับที่",
    width: 80,
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "questionTitle",
    headerName: "Title Question",
    width: 500,
  },
];

const PopupAddCustomQuestion = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  subject,
  setDataRandomExams,
  dataRandomExams,
}) => {
  const dispatch = useDispatch();
  const { customQuestions, total, limit, customQuestionStorage } = useSelector(
    (state) => state.campaign
  );

  const initFilter = {
    code: "",
    examOwner: "",
    subjectId: +subject,
    topicName: "",
    level: "",
    question: "",
  };

  const [questionList, setQuestionList] = useState(customQuestions);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [questionArr, setQuestionArr] = useState([]);

  const handleSearch = () => {
    setPage(0);
    dispatch(getCustomQuestionList());
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setFilter({ ...filter, subjectId: +subject });
  }, [subject]);

  useEffect(() => {
    let index = 1;
    let newItems = customQuestions.map((it) => {
      return { ...it, no: index++ };
    });
    setQuestionList(newItems);
  }, [customQuestions]);

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getCustomQuestionList(filter, nextPage * limit));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getCustomQuestionList(initFilter));
  };

  const selectQuestion = (idList) => {
    if (idList.length === 0) return;
    let newArr = [...customQuestionStorage];
    for (const id of idList) {
      let isExist = newArr.some((it) => it.id === id);
      if (!isExist) {
        let item = questionList.find((it) => it.id === id);
        newArr.push(item);
      }
    }
    setQuestionArr(newArr);
  };

  const handleSubmitAddCustomQuestion = () => {
    console.log(questionArr);
    dispatch(addCustomQuestionStorage(questionArr));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">เพิ่มชุดข้อสอบเข้า Assessment</div>
            <div className="content-wrapper">
              <div className="form-wrapper">
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      label="รหัส ID"
                      value={filter.code}
                      name="search_code"
                      onChange={(e) =>
                        handleFilterChange("code", e.target.value)
                      }
                    />
                    <TextField
                      label="Title Question"
                      value={filter.name}
                      name="search_name"
                      onChange={(e) =>
                        handleFilterChange("name", e.target.value)
                      }
                    />
                    <TextField
                      label="Library"
                      value={filter.library}
                      name="search_library"
                      onChange={(e) =>
                        handleFilterChange("library", e.target.value)
                      }
                    />
                  </div>
                </div>
                <br />
                <div>
                  <div className="section-group ">
                    <div className="group-field end">
                      <Button onClick={() => handleSearch()}>Apply</Button>
                      <Button onClick={() => handleClearFilter()} outlined>
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <Table
                  columns={columns}
                  rows={questionList}
                  checkboxSelection={true}
                  rowCount={total}
                  pageSize={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onSelectionChange={(id) => selectQuestion(id)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => handleSubmitAddCustomQuestion()}>
            ยืนยัน
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupAddCustomQuestion;
