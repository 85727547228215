import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import TextField from "components/form/TextField";

import Grid from "@mui/material/Grid";

import { getRandomExam } from "redux/actions/library";
import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";
import ButtonIconDeleteCircle from "components/form/ButtonIconDeleteCircle";
import ButtonIconAddCircle from "components/form/ButtonIconAddCircle";
import Autocomplete from "@mui/material/Autocomplete";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 18px;
  }

  .level_container {
    display: flex;
    margin-bottom: 5px;

    .level_selection {
      margin-right: 5px;
    }
    .level_field {
      margin-right: 5px;
    }
    .level_btn {
      margin-top: 7px;
    }
  }

  .right {
    text-align: right;
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const difficultyLevelList = [
  { value: 1, text: "Level 1" },
  { value: 2, text: "Level 2" },
  { value: 3, text: "Level 3" },
  { value: 4, text: "Level 4" },
  { value: 5, text: "Level 5" },
  { value: 6, text: "Level 6" },
  { value: 7, text: "Level 7" },
  { value: 8, text: "Level 8" },
  { value: 9, text: "Level 9" },
  { value: 10, text: "Level 10" },
  { value: 11, text: "Level 11" },
];

const PopupRandomExamShuffleOrNormal = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  randomType = "",
  subject,
}) => {
  const dispatch = useDispatch();
  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);
  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [dataJob, setDataJob] = useState(
    masterDataJob.map((it) => ({ label: it.text, id: it.value }))
  );

  const [subjectId, setSubjectId] = useState(subject);
  const [topicName, setTopicName] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const [totalQuestion, setTotalQuestion] = useState("");
  const [level, setLevel] = useState("");

  useEffect(() => {
    setSubjectId(subject);
  }, [subject]);

  // const handleSubjectChange = (value) => {
  //   setSubjectId(value);
  // };

  const handleJobChange = (event, value) => {
    setJobPositionId(value?.id);
  };

  const handleLevelChange = (value) => {
    setLevel(value);
  };

  const submitRandom = () => {
    let obj = {
      random_type: randomType,
      subject_id: +subjectId,
      topic_name: topicName,
      job_position_id: +jobPositionId,
      levels: [
        {
          level: +level,
          total_question: +totalQuestion,
        },
      ],
    };
    dispatch(getRandomExam(obj)).then(() => onClose());
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">สุ่มข้อสอบเข้าสู่ชุดข้อสอบ</div>
            <div className="mb-3">
              <TextField
                placeholder="หัวข้อ"
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                className="field_topicName"
              />
            </div>
            <div className="mb-3">
              <Select
                items={difficultyLevelList}
                value={level}
                defaultValue="Level"
                className="field_level"
                onChange={(e) => handleLevelChange(e.target.value)}
              />
            </div>
            <div className="mb-3">
              {/* <Select
                items={dataJob}
                value={jobPositionId}
                defaultValue="ตำแหน่งงาน"
                className="field_job_position_id"
                onChange={(e) => handleJobChange(e.target.value)}
              /> */}
              <Autocomplete
                disablePortal
                options={dataJob}
                sx={{ width: 300 }}
                onChange={handleJobChange}
                id="field-job"
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <span>{option.label}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="งานตำแหน่ง" />
                )}
              />
            </div>
            <div className="mb-3">
              <TextField
                placeholder="จำนวนข้อสอบ"
                value={totalQuestion}
                type="number"
                onChange={(e) => setTotalQuestion(e.target.value)}
                className="field_total_question"
              />
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => submitRandom()}>Random </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupRandomExamShuffleOrNormal;
