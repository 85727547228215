import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import Select from "components/form/Select";
import Date from "components/form/Date";

import { updateInvoiceStatus } from "redux/actions/order";
import { selectMasterDataBank } from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    row-gap: 10px;
    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
    .appove-panel {
      display: flex;
      align-items: stretch;
      column-gap: 10px;
    }
    .approve-status-bar {
      position: relative;
      top: -15px;
      left: -15px;
    }
    .preview-image {
      :hover {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const StyleCardWrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
`;

const paymentStatusList = [
  {
    text: "ยืนยันจ่ายเงิน",
    value: 2,
  },
  {
    text: "ยกเลิกจ่ายเงิน",
    value: 3,
  },
];

const paymentMethodList = [
  {
    text: "Credit Card",
    value: 1,
  },
  {
    text: "QR Code",
    value: 2,
  },
];

const PopupUploadPayment = ({ open, onClose, maxWidth = "xl", invoiceId }) => {
  const dispatch = useDispatch();
  const [paymentImgUrl, setPaymentImgUrl] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const masterDataBank = useSelector(selectMasterDataBank);
  const [bankAccount, setBankAccount] = useState("");
  const [paymentDate, setPaymentDate] = useState(null);

  const handlePaymentStatusChange = (value) => {
    setPaymentStatus(value);
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const submitUpdateInvoiceStatus = () => {
    let obj = {
      slip_image_url: paymentImgUrl,
      invoice_status: +paymentStatus,
      bank: +bankAccount,
      payment_date: paymentDate,
      payment_method: +paymentMethod,
    };

    dispatch(updateInvoiceStatus(obj, invoiceId));
    onClose();
  };

  const handleDatePaymentChange = (date) => {
    setPaymentDate(date);
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">อัพโหลดหลักฐานการโอนเงิน</div>
          <Select
            items={masterDataBank}
            value={bankAccount}
            onChange={(e) => setBankAccount(e.target.value)}
            className="field-bankAccount"
            id="field-bankAccount"
            defaultValue="เลือกธนาคาร"
          />
          <Date
            placeholder="วัน-เวลาที่โอน"
            value={paymentDate}
            disableFuture
            onChange={(date) => handleDatePaymentChange(date)}
          />
          <Select
            items={paymentMethodList}
            value={paymentStatus}
            onChange={(e) => handlePaymentMethodChange(e.target.value)}
            defaultValue="Payment method"
            className="field-payment-method"
          />
          <Select
            items={paymentStatusList}
            value={paymentStatus}
            onChange={(e) => handlePaymentStatusChange(e.target.value)}
            defaultValue="เลือกสถานะการโอนเงิน"
            className="field-payment-status"
          />
          <UploadImage
            inputID={"payment_img_url"}
            setURLImage={setPaymentImgUrl}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Close{" "}
          </Button>
          <ButtonBlack onClick={submitUpdateInvoiceStatus}>ยืนยัน</ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupUploadPayment;
