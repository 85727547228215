import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import { login } from "redux/actions/auth";

import { deleteUserSegmentItem } from "redux/actions/member";

import { selectMasterDataTag } from "../../redux/selectors";

const STATUS = {
  loading: "loading",
  success: "success",
  error: "error",
};

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-size: 20px;
      text-align: center;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }
`;

const PopupConfirmDeleteUserSagment = ({
  open,
  onClose,
  label,
  dataDelete = {},
  isDelete = false,
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
  segmentId,
}) => {
  const dispatch = useDispatch();
  const [userSegment, setUserSegment] = useState();

  const submitDeleteItem = () => {
    dispatch(deleteUserSegmentItem(userSegment));
    onClose();
  };

  useEffect(() => {
    if (isDelete) {
      setUserSegment({
        flag: "delete",
        member: [
          {
            member_id: dataDelete.id,
            segment_id: segmentId,
          },
        ],
      });
    }
  }, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">ยืนยันการลบข้อมูล</div>

          <p className="form-topic">ต้องการลบข้อมูลใช่หรือไม่?</p>
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitDeleteItem()}>
            Confirm
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupConfirmDeleteUserSagment;
