import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import { useHistory } from "react-router-dom";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const OrderRegconsign = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <SidebarLayout title="Order Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/order/transaction", text: "Transaction" },
            { path: "/order/regconsign", text: "Regconsign" },
            { path: "/order/summary", text: "SummaryReport" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Regconsign</div>
            </div>
            {/* <div className="section-group">
              <div className="group-title"></div>
              <div className="group-field">
                <Select
                  name="search_email"
                  value={filter.paymentStatus}
                  defaultValue="Select Payment Status"
                  items={paymentStatusList}
                />
              </div>
            </div> */}
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default OrderRegconsign;
