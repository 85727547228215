import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: #0075ca;
  text-decoration: underline;
`;
const RouterLinkNewTabCell = ({ to, children }) => (
  <StyledLink href={to} target="_blank">
    {children}
  </StyledLink>
);

export default RouterLinkNewTabCell;
