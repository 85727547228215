/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Card, CardContent, Typography } from '@material-ui/core'
import BaseModal from '../../components/modal/BaseModal'

const Div = styled.div`
  padding: 20px;

  .title {
    text-align: center;
    font-size: 32px;
    font-family: 'kelson_sansbold';
  }
`
const PopupStatusCell = ({ open, onClose, status, children, maxWidth }) => {
  return (
    <BaseModal open onClose={onClose} disabledSelfClose={false} maxWidth={maxWidth}>
      <Div>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Status: {status}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti minus, laboriosam
              dolorem inventore, veritatis natus aliquam, deleniti ullam quae eum iste? Expedita
              saepe nam alias maiores sit consectetur incidunt quas.
            </Typography>
          </CardContent>
        </Card>
      </Div>
    </BaseModal>
  )
}

export default PopupStatusCell
