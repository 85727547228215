import React from "react";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

const Div = styled.div`
  .welcome-wrapper {
    margin-top: 29px;
    height: 870px;
    padding: 228px 120px;
    font-family: "kelson_sansbold";
    background: #fff;
    border-radius: 8px;

    .sub-title {
      font-size: 32px;
      color: #080808;
    }

    .title {
      font-size: 56px;
      color: #080808;
      margin-top: 36px;
    }
  }
`;

const LibaryWelcome = () => {
  return (
    <SidebarLayout title="Order Management">
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
            { path: "/library/question/all", text: "คำถามเพิ่มเติม" },
            { path: "/library/subject/all", text: "วิชา" },
          ]}
        />
        <div className="welcome-wrapper">
          <div className="sub-title">Welcome to</div>
          <div className="title">Center Libary Management</div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default LibaryWelcome;
