import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

// image
import logo from "assets/images/logo_auction.png";
import signature from "assets/images/signature_default.png";
// Component

import formatValue from "utils/formatValue";

import { bahttext } from "bahttext";

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  * {
    color: #020202;
  }
  @media print {
    background: white;
    width: 90%;
    display: block;
    margin: 0.5cm 0 0 0.5cm;

    .signature_content {
      page-break-before: auto;
    }
    .print_none {
      display: none;
      /* margin: 2px 0;  */
      /* visibility: hidden; */
    }
  }

  .bold {
    font-weight: bold;
  }
  .text_end {
    text-align: right;
  }
  .gap_td1 {
    padding-right: 20px;
  }

  .header_topic {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_content {
      display: flex;
      align-items: center;
      column-gap: 30px;

      .box_topics {
        p {
          font-family: "Thonburi";
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

    .btn_invoice {
      font-family: "Thonburi";
      display: flex;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      font-size: 18px;
      padding: 8px;
      height: 60px;
      min-width: 130px;
      border: none;
      color: #020202;
      background-color: #eff6ff;
      border-radius: 4px;
      box-shadow: none;
    }
  }

  hr {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #dadada;
  }

  .info_content {
    .address_acution {
      margin-bottom: 20px;

      .span_phone {
        float: right;
        margin-right: 10px;
      }
    }
    .address_buyer,
    .address_seller {
      text-align: right;
      background: #f3f5f8;
      padding: 20px 30px;
      border-radius: 4px;
      margin-bottom: 15px;
    }
  }
  .summary_info {
    .thead_main {
      display: table-header-group;
      /* font-size: 16px; */
      .header_topic {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left_content {
          display: flex;
          align-items: center;
          column-gap: 30px;

          .box_topics {
            p {
              font-family: "Thonburi";
              font-size: 18px;
              line-height: 25px;
            }
          }
        }

        .btn_invoice {
          font-family: "Thonburi";
          display: flex;
          justify-content: center;
          font-weight: bold;
          align-items: center;
          font-size: 18px;
          height: 60px;
          padding: 8px;
          border: none;
          color: #020202;
          background-color: #eff6ff;
          border-radius: 4px;
          box-shadow: none;
        }
      }

      hr {
        margin: 10px 0;
        border: none;
        border-top: 1px solid #dadada;
      }

      .address_acution {
        /* margin-bottom: 20px; */

        .span_phone {
          float: right;
          margin-right: 10px;
        }
      }
      .address_buyer,
      .address_seller {
        background: #f3f5f8;
        padding: 10px 15px;
        border-radius: 4px;
        /* margin-bottom: 15px; */
      }
    }
    .table_summary_price {
      font-size: 16px;
      th {
        background-color: #f3f5f8;
        text-align: center;
        padding: 10px;
        border: 1px solid #020202;
      }
      td {
        border: 1px solid #020202;
        padding: 10px 25px;
        vertical-align: baseline;
      }
    }
    .table_no_border {
      td {
        border: initial;
        font-size: 14px;
        padding: 4px 0;
      }
    }
  }
  .signature_content {
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 80px auto 50px;
    hr {
      width: 100%;
      border-top: 1px solid #505050;
    }
    .signature_buyer,
    .signature_seller {
      width: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .detail_content {
    p {
      margin-bottom: 5px;
    }
  }
  .center {
    text-align: center;
  }
`;

export const Receipt = React.forwardRef(({ info, ...props }, ref) => {
  return (
    <Wrapper ref={ref} {...props}>
      <div className="header_topic m-0">
        <div className="left_content m-0">
          <div className="box_image_logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="box_topics m-0">
            <p className="bold m-0">AUCTION EXPRESS CO., LTD.</p>
            <p>บริษัท อ็อคชั่น เอ็กซ์เพรส จำกัด</p>
          </div>
        </div>
        <label className="btn_invoice">ใบเสร็จรับเงิน/ใบกำกับภาษี</label>
      </div>
      <hr />

      <div className="summary_info">
        <table width="100%">
          <thead className="thead_main">
            <tr>
              <td colSpan="7">
                <table className=" address_acution " width="100%">
                  <tbody>
                    <tr>
                      <td className="bold gap_td1">เลขที่ (No.) :</td>
                      <td width="50%">RV</td>
                      <td className="text_end">
                        <td>วันที่ (Date):</td>
                        <td>{info?.createdAt} </td>
                      </td>
                    </tr>
                    <tr>
                      <td className="bold gap_td1">สำนักงานใหญ่ :</td>
                      <td>
                        เลขที่ 199 หมู่ 7 ตำบลบางเสาธง อำเภอบางเสาธง
                        จังหวัดสมุทรปราการ 10570
                      </td>
                    </tr>
                    <tr>
                      <td className="bold gap_td1">เลขประจำตัวผู้เสียภาษี :</td>
                      <td>
                        0115563024295
                        <span className="span_phone">
                          <span className="bold gap_td1">โทร : </span>
                          <span>020277885</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="7">
                        <div className="address_seller">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td className="bold" width="30%">
                                  ออกในนามของผู้ขายหรือเจ้าของ :
                                </td>
                                <td>{info?.address_name}</td>
                              </tr>
                              <tr>
                                <td className="bold ">ที่อยู่ : </td>
                                <td>{info?.address_th}</td>
                              </tr>
                              <tr>
                                <td className="bold ">
                                  เลขประจำตัวผู้เสียภาษี :{" "}
                                </td>
                                <td>{info?.tax_id_no}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </th>
                    </tr>

                    <tr>
                      <th colSpan="7">
                        <div className="address_buyer ">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td className="bold gap_td1" width="30%">
                                  ชื่อลูกค้า :{" "}
                                </td>
                                <td>{info?.address_name}</td>
                              </tr>
                              <tr>
                                <td className="bold ">ที่อยู่ : </td>
                                <td>{info?.address_th}</td>
                              </tr>
                              <tr>
                                <td className="bold ">
                                  เลขประจำตัวผู้เสียภาษี :{" "}
                                </td>
                                <td>{info?.tax_id_no}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="print_none">
              <td colSpan="7">
                <hr />
              </td>
            </tr>
            <tr className="table_summary_price">
              <th>No.</th>
              <th>Quotation No</th>
              <th>Invoice No</th>
              <th>ราคาไม่รวมภาษี มูลค่าเพิ่ม</th>
              <th>ภาษีมูลค่าเพิ่ม</th>
              <th>จำนวนเงินรวมภาษี มูลค่าเพิ่ม</th>
            </tr>
          </thead>
          <tbody className="table_summary_price">
            {info?.invoice &&
              info?.invoice.map((v, i) => (
                <tr key={i}>
                  <td align="center">{i + 1}</td>
                  <td className="bold center">{info?.quotation_no}</td>
                  <td className="bold center">{v?.invoice_no}</td>
                  <td>{formatValue("Currency", v?.total_price)}</td>
                  <td>
                    {formatValue(
                      "Currency",
                      (v?.total_tax / 100) * v?.total_price
                    )}
                  </td>
                  <td>{formatValue("Currency", v?.total_net)}</td>
                </tr>
              ))}

            <tr>
              <td colSpan="3" className="bold">
                จำนวนเงินรวม
              </td>
              <td align="right">
                {formatValue("Currency", info?.total_price)}
              </td>
              <td align="right">{formatValue("Currency", info?.total_vat)}</td>
              <td align="right" className="bold">
                {formatValue("Currency", info?.total_price_vat)}
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="bold">
                ภาษี ณ ที่จ่าย (3 %)
              </td>
              <td colSpan="3" align="right">
                {formatValue("Currency", (info?.vat / 100) * info?.total_price)}
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="bold">
                จำนวนเงินสุทธิ: &nbsp;&nbsp;&nbsp;
                {bahttext(info?.total_net)}
              </td>
              <td colSpan="3" align="right">
                {formatValue("Currency", info?.total_net)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="signature_content">
        <div className="signature_buyer">
          <p> </p>
          <hr />
          <p> ผู้ซื้อ</p>
        </div>
        <div className="signature_seller">
          <p>บริษัท อ็อคชั่น เอ็กซ์เพรส จำกัด</p>
          <hr />
          <p> ลายเซ็นผู้มีอำนาจ</p>
        </div>
      </div>
    </Wrapper>
  );
});

export default Receipt;
