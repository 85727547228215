import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Autocomplete from "@mui/material/Autocomplete";

import {
  updateExamQuestion,
  updateExamQuestionImport,
} from "redux/actions/library";

import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .wrapper-item {
    margin-bottom: 20px;
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const difficultyLevelList = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
  { value: 6, text: 6 },
  { value: 7, text: 7 },
  { value: 8, text: 8 },
  { value: 9, text: 9 },
  { value: 10, text: 10 },
  { value: 11, text: 11 },
];

const PopupExamQuestionUpdate = ({
  open,
  onClose,
  dataEdit = {},
  maxWidth = "xl",
  id,
  type = "",
}) => {
  const dispatch = useDispatch();

  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);

  const [dataSubject, setDataSubject] = useState(masterDataSubject);
  const [dataJobs, setDataJobs] = useState(
    masterDataJob.map((it) => ({ label: it.text, id: it.value }))
  );

  const [subjectId, setSubjectId] = useState("");
  const [topicName, setTopicName] = useState("");
  const [level, setLevel] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const [examOwner, setExamOwner] = useState("");
  const [moduleName, setModuleName] = useState("");

  useEffect(() => {
    let subjectArr = masterDataSubject.map((it) => ({
      label: it.text,
      id: it.value,
    }));
    setDataSubject(subjectArr);
  }, [masterDataSubject]);

  useEffect(() => {
    setSubjectId(dataEdit?.subject_id);
    setTopicName(dataEdit?.topic_name);
    setLevel(dataEdit?.level);
    setJobPositionId(dataEdit?.job_position_id);
    setExamOwner(dataEdit?.exam_owner);
  }, [dataEdit]);

  const handleSubjectChange = (event, value) => {
    setSubjectId(value?.id);
  };

  const handleLevelChange = (value) => {
    setLevel(value);
  };

  const handleJobChange = (event, value) => {
    setJobPositionId(value?.id);
  };

  const submitQuestionUpdate = (event) => {
    event.preventDefault();

    let obj = {
      subject_id: +subjectId,
      topic_name: topicName,
      level: +level,
      job_position_id: +jobPositionId,
      exam_owner: examOwner,
      module_name: "exam_info",
    };
    console.log("obj", obj);
    console.log("obj", jobPositionId);
    if (type === "import") {
      dispatch(updateExamQuestionImport(obj, id));
      onClose();
    } else {
      dispatch(updateExamQuestion(obj, id)).then((data) => onClose());
    }
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <form onSubmit={submitQuestionUpdate}>
          <Grid container spacing={2}>
            <Grid item xs>
              <div className="title">แก้ไขข้อมูลเกี่ยวกับข้อสอบ</div>
              <p>
                <strong>รหัสข้อสอบ {dataEdit?.code}</strong>
              </p>
              <div className="wrapper-item">
                <Autocomplete
                  disablePortal
                  options={dataSubject}
                  sx={{ width: 300 }}
                  onChange={handleSubjectChange}
                  id="field-subject"
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="วิชา" />
                  )}
                />
              </div>
              <div className="wrapper-item">
                <TextField
                  label="หัวข้อ"
                  value={topicName}
                  onChange={(e) => setTopicName(e.target.value)}
                  className="field-topic"
                  required
                />
              </div>
              <div className="wrapper-item">
                <Select
                  label="ระดับความยาก"
                  items={difficultyLevelList}
                  value={level}
                  onChange={(e) => handleLevelChange(e.target.value)}
                  defaultValue="ระดับความยาก"
                  className="field-level"
                />
              </div>
              <div className="wrapper-item">
                {/* <Select
                  label="งานตำแหน่ง"
                  items={dataJobs}
                  value={jobPositionId}
                  defaultValue="งานตำแหน่ง"
                  className="field-jobposition"
                  onChange={(e) => handleJobChange(e.target.value)}
                /> */}
                <Autocomplete
                  disablePortal
                  options={dataJobs}
                  sx={{ width: 300 }}
                  onChange={handleJobChange}
                  id="field-job"
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="งานตำแหน่ง" />
                  )}
                />
              </div>
              <div className="wrapper-item">
                <TextField
                  label="ชื่อผู้ออกข้อสอบ"
                  value={examOwner}
                  onChange={(e) => setExamOwner(e.target.value)}
                  className="field-owner"
                  required
                />
              </div>
            </Grid>
          </Grid>
          <div className="button-wrapper">
            <Button onClick={onClose} outlined>
              ยกเลิก{" "}
            </Button>
            <ButtonBlack type="submit">แก้ไขข้อมูล </ButtonBlack>
          </div>
        </form>
      </Div>
    </BaseModal>
  );
};

export default PopupExamQuestionUpdate;
