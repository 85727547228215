import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Grid from "@mui/material/Grid";
import { ArrowBack as BackIcon } from "@material-ui/icons";

import PopupProductQuotation from "containers/popup/PopupProductQuotation";

import {} from "redux/selectors";

import { getInvoiceById } from "redux/actions/order";
import { checkValue } from "../../utils/common";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  button {
    width: 100%;
  }
`;

const orderTypeList = [
  { value: 1, text: "Order Type 1" },
  { value: 2, text: "Order Type 2" },
  { value: 3, text: "Order Type 3" },
];

const OrderTransactionGenerateDeliveryNote = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { invoiceById } = useSelector((state) => state.order);

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getInvoiceById(id));
  }, [id]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "No.",
    },
    {
      field: "product_code",
      headerName: "รหัสสินค้า",
    },
    {
      field: "product_name",
      headerName: "Product Name",
    },
    { field: "price_per_unit", headerName: "Price" },

    { field: "product_detail", headerName: "Product Details" },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const submitCreate = (event) => {
    event.preventDefault();
    let obj = {
      invoice_id: id,
    };

    window.open(`/delivery/${id}`, "_blank");
    // history.goBack();
  };

  return (
    <SidebarLayout title="Order Management">
      <Div>
        <form onSubmit={submitCreate}>
          <div className="content-wrapper">
            <div className="form-wrapper">
              <div className="selection-group">
                <Grid container spacing={2}>
                  <Grid item xs>
                    <div className="title">
                      <BackIcon
                        className="back-icon"
                        onClick={() => history.goBack()}
                      />
                      Generate Delivery Note
                    </div>
                  </Grid>

                  <Grid item>
                    <Button className="button-invite" type="submit">
                      Create Delivery Note
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="section-group">
                <div className="group-title"></div>
                <div className="group-field">
                  <Select
                    label="ประเภท Order"
                    name="search_orderType"
                    defaultValue="Select Order Type"
                    value={checkValue(invoiceById?.quotation_type)}
                    disabled
                    items={orderTypeList}
                  />
                  <TextField
                    label="เลขที่สมาชิก"
                    value={checkValue(invoiceById?.invoice_no)}
                    name="search_memberNo"
                    disabled
                  />
                  <TextField
                    label="เลข Project"
                    value={checkValue(invoiceById?.project_no)}
                    name="search_projectNo"
                    disabled
                  />
                  <TextField
                    label="เลขโครงการ"
                    value={checkValue(invoiceById?.program_no)}
                    name="search_projectNumber"
                    disabled
                  />
                </div>
              </div>
              <div className="section-group">
                <div className="group-title"></div>
                <div className="group-field">
                  <TextField
                    label="Payment Term"
                    // name="payment_term"
                    placeholder={"Payment Term (1-100)%"}
                    disabled
                    value={invoiceById?.payment_term}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <Table
                columns={columns}
                rows={invoiceById?.invoiceDetail}
                checkboxSelection={false}
                // rowCount={total}
                // pageSize={limit}
                page={page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </form>
      </Div>
    </SidebarLayout>
  );
};

export default OrderTransactionGenerateDeliveryNote;
