/* eslint-disable no-confusing-arrow */
import React from "react";
import styled, { css } from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
  color: #252631;
  text-align: center;
  width: 155px;
  height: 38px;
  border-radius: 4px;

  ${({ type }) =>
    type === "success"
      ? css`
          background: #edfff7;
          color: #2bba7e;
        `
      : null}

  ${({ type }) =>
    type === "active"
      ? css`
          background: #edfff7;
          color: #2bba7e;
        `
      : null}

  ${({ type }) =>
    type === "fail" || type === "disabled"
      ? css`
          background: #ffe0e3;
          color: #e44c5c;
        `
      : null}

  ${({ type }) =>
    type === "pending"
      ? css`
          background: #f29339;
          color: #f7cb73;
        `
      : null}

${({ type }) =>
    type === "3"
      ? css`
          background: #ffe0e3;
          color: #e44c5c;
        `
      : null}

  ${({ type }) =>
    type === "2"
      ? css`
          background: #f0e68c;
          color: #a89107;
        `
      : null}

  ${({ type }) =>
    type === "1"
      ? css`
          background: #72e193;
          color: #2bba04;
        `
      : null}

  ${({ type }) =>
    type === "-"
      ? css`
          color: #e44c5c;
        `
      : null}
`;

const StatusCell = ({ className, type, children, ...props }) => (
  <Div className={className} type={type} {...props}>
    {children}
  </Div>
);

export default StatusCell;
