import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";
import { login } from "redux/actions/auth";

import { addSegment, updateSegment } from "redux/actions/member";

import { selectMasterDataTag } from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }
`;

const PopupAddNewSagment = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isEdit = false,
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
}) => {
  const dispatch = useDispatch();
  const masterDataTag = useSelector(selectMasterDataTag);

  const [dataTag, setDataTag] = useState([
    { value: 1, text: "TLT" },
    { value: 2, text: "CMS" },
  ]);
  const [segmentName, setSegmentName] = useState("");
  const [tag, setTag] = useState("");
  const [errors, setErrors] = useState({});

  const handleTagChange = (value) => {
    setTag(value);
  };

  const submitAddSegment = () => {
    if (!validate()) {
      const obj = {
        name_th: segmentName,
        tag: tag,
      };
      dispatch(addSegment(obj));
      onClose();
    }
  };

  const submitEditSegment = () => {
    if (!validate()) {
      let obj = {
        name_th: segmentName,
        tag: tag,
        flag: "updateinfo",
      };
      dispatch(updateSegment(obj, dataEdit.id));
      onClose();
    }
  };

  const DisplayLabelButton = () => {
    if (isEdit) return "Edit";
    return "Add";
  };

  const handleEditSegmentFrom = () => {
    if (isEdit) {
      setSegmentName(dataEdit.nameTh);
      setTag(dataEdit.tag);
    }
  };

  const validate = (
    fieldValues = {
      segmentName,
      tag,
    }
  ) => {
    let temp = { ...errors };
    if ("segmentName" in fieldValues)
      temp.segmentName =
        fieldValues.segmentName !== "" ? "" : "กรุณากรอกชื่อ Segment";
    if ("tag" in fieldValues)
      temp.tag = fieldValues.tag !== "" ? "" : "กรุณาเลือก Tag";

    setErrors({ ...temp });
    return segmentName === "" || tag === "";
  };

  useEffect(() => {
    handleEditSegmentFrom();
  }, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">{isEdit ? "Edit" : "Add"} New Segment</div>

          <p className="form-topic">Sagment Name</p>
          <InlineSpaceText label="">
            <TextField
              value={segmentName}
              onChange={(e) => setSegmentName(e.target.value)}
              className="field-sagmentName"
              error={errors.segmentName}
            />
          </InlineSpaceText>

          <p className="form-topic">Tag</p>
          <InlineSpaceText label="">
            <Select
              items={dataTag}
              value={tag}
              onChange={(e) => handleTagChange(e.target.value)}
              defaultValue="Select Tag"
              className="field-tag"
              errorText={errors.tag}
              isError={errors.tag}
            />
          </InlineSpaceText>
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack
            type="submit"
            onClick={() => (isEdit ? submitEditSegment() : submitAddSegment())}
          >
            {DisplayLabelButton()}
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupAddNewSagment;
