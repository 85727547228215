import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#080808",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#ffffff",
    },
    danger: {
      main: "#a32330",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        padding: 15,
        minWidth: 255,
        minHeight: "auto",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        "& .MuiButtonBase-root": {
          padding: 3,
        },
        "& .MuiTypography-root": {
          fontSize: 14,
        },
      },
      dayLabel: {
        color: "#2c2c2c",
        width: 28,
      },
    },
    MuiPickersDay: {
      day: {
        width: 28,
        height: 28,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 165,
      },
    },
  },
});
