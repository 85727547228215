import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

import { getDetailQuestionByCampaign } from "redux/actions/report";
import ButtonIcon from "components/form/ButtonIcon";
import Radio from "components/form/Radio";
import DeltaToHtml from "components/form/DeltaToHtml";
import ReactHtmlParser from "react-html-parser";

const Div = styled.div`
  padding: 20px;
  margin: 0 auto;

  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  p {
    margin-top: 0;
  }

  .question-number {
    font-weight: bold;
  }

  .question-description {
    font-size: 20px;
  }

  .choice {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    row-gap: 10px;
    .choice-box {
      border-radius: 8px;
      padding: 5px;
      border: 1px solid #000;
      display: flex;
      column-gap: 5px;
      align-items: center;

      .choice-box__radio {
        flex: 1;
      }
      .choice-box__text {
        flex: 3;
        p {
          margin: 0;
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
`;

const PopupDetailQuestion = ({
  open,
  onClose,
  maxWidth = "sm",
  campaignData,
}) => {
  const dispatch = useDispatch();
  const { examsetQuestionById } = useSelector((state) => state.report);

  const [examSetDisplay, setExamSetDisplay] = useState();
  const [examsetDDL, setExamsetDDL] = useState([]);

  const [questionNo, setQuestionNo] = useState(1);
  const [questionSet, setQuestionSet] = useState();
  const [displayQuestion, setDisplayQuestion] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState();
  const [questionSubject, setQuestionSubject] = useState(1);
  const [selectExamset, setSelectExamset] = useState("");
  const [currentExamset, setCurrentExamset] = useState();

  useEffect(() => {
    let params = {
      // slug: campaignData?.slug ,
      slug: "accountant-person-test-26jul22-abdf7c6d157e",
      userUid: "18a3672e3235dff69f5b0a4bc05838",
    };
    dispatch(getDetailQuestionByCampaign(params));
  }, []);

  useEffect(() => {
    let examsetDDL = examsetQuestionById.map((item, index) => ({
      text: item?.set_code,
      value: index,
    }));

    setExamsetDDL(examsetDDL);
  }, [examsetQuestionById]);

  useEffect(() => {
    const quesetionSet = examsetQuestionById?.[selectExamset];

    setCurrentExamset(quesetionSet);
  }, [selectExamset]);

  useEffect(() => {
    let item = currentExamset?.question?.[displayQuestion];
    setExamSetDisplay(item);
    setQuestionNo(displayQuestion + 1);
    setQuestionSet(currentExamset?.set_code);
    setQuestionSubject(currentExamset?.subject);
    setTotalQuestion(currentExamset?.question?.length);
  }, [displayQuestion, currentExamset]);

  const DisplyDeltaOrHtml = (value) => {
    const isDelta = value?.startsWith("[");
    if (isDelta) return <DeltaToHtml contents={value} />;
    return ReactHtmlParser(value);
  };

  return (
    <BaseModal fullWidth open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              label="ชุดข้อสอบ"
              name="examset"
              defaultValue="กรุณาเลือกชุดข้อสอบ"
              value={selectExamset}
              items={examsetDDL}
              onChange={(e) => setSelectExamset(+e.target.value)}
            />
          </Grid>
          {examSetDisplay && (
            <Grid item xs={12}>
              {/* <div className="title">Custom Question</div> */}
              <p className="question-number">ชุดข้อสอบ {questionSet}</p>
              <p className="question-number">วิชา {questionSubject}</p>
              <p className="question-number">ข้อที่ {questionNo}</p>
              {/* <p className="question-description">&emsp; 1+1 = ?</p> */}
              <p className="question-description">
                {DisplyDeltaOrHtml(examSetDisplay?.choice_description)}
              </p>

              <div className="choice">
                {examSetDisplay?.choice?.map((choice, index) => (
                  <div className="choice-box">
                    <Radio
                      className="choice-box__radio"
                      checked={choice?.is_answer}
                      key={index}
                    />

                    <div className="choice-box__text">
                      {DisplyDeltaOrHtml(choice?.choice_description)}
                    </div>
                  </div>
                ))}
              </div>
              <p>Correct Answer : {examSetDisplay?.answer_check}</p>
            </Grid>
          )}
        </Grid>
        <div className="button-wrapper">
          <ButtonIcon
            disabled={totalQuestion === 0 || displayQuestion === 0}
            onClick={() => setDisplayQuestion(displayQuestion - 1)}
          >
            <ArrowBack color="dark" /> Back
          </ButtonIcon>
          <ButtonIcon
            disabled={totalQuestion === displayQuestion + 1}
            onClick={() => setDisplayQuestion(displayQuestion + 1)}
          >
            Next <ArrowForward />
          </ButtonIcon>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupDetailQuestion;
