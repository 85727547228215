import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

import { getDetailCustomQuestionByCampaign } from "redux/actions/report";
import ButtonIcon from "components/form/ButtonIcon";
import Radio from "components/form/Radio";
import DeltaToHtml from "components/form/DeltaToHtml";
import ReactHtmlParser from "react-html-parser";
import TextField from "components/form/TextField";
import TextFieldArea from "components/form/TextFieldArea";

const Div = styled.div`
  padding: 20px;
  margin: 0 auto;

  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  p {
    margin-top: 0;
  }

  .question-number {
    font-weight: bold;
  }

  .question-description {
    font-size: 20px;
  }

  .choice {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    row-gap: 10px;
    .choice-box {
      border-radius: 8px;
      padding: 5px;
      border: 1px solid #000;
      display: flex;
      column-gap: 5px;
      align-items: center;

      .choice-box__radio {
        flex: 1;
      }
      .choice-box__text {
        flex: 3;
        p {
          margin: 0;
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
`;

const PopupDetailCustomQuestion = ({
  open,
  onClose,
  maxWidth = "sm",
  campaignData,
}) => {
  const dispatch = useDispatch();
  const { examsetCustomQuestionById } = useSelector((state) => state.report);

  const [examSetDisplay, setExamSetDisplay] = useState();
  const [examsetDDL, setExamsetDDL] = useState([]);

  const [questionNo, setQuestionNo] = useState(1);
  const [displayQuestion, setDisplayQuestion] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState();

  useEffect(() => {
    let params = {
      // slug: campaignData?.slug ,
      slug: "accountant-person-test-26jul22-abdf7c6d157e",
      userUid: "58faa434a29534b9ea64633c24a21c",
    };
    dispatch(getDetailCustomQuestionByCampaign(params));
  }, []);

  useEffect(() => {
    let item = examsetCustomQuestionById?.[displayQuestion];
    setExamSetDisplay(item);
    setQuestionNo(displayQuestion + 1);
    setTotalQuestion(examsetCustomQuestionById?.length);
  }, [displayQuestion, examsetCustomQuestionById]);

  const DisplyDeltaOrHtml = (value) => {
    const isDelta = value?.startsWith("[");
    if (isDelta) return <DeltaToHtml contents={value} />;
    return ReactHtmlParser(value);
  };

  return (
    <BaseModal fullWidth open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {/* <div className="title">Custom Question</div> */}
            <p className="question-number">Custom Question {questionNo}</p>
            {/* <p className="question-description">&emsp; 1+1 = ?</p> */}
            <p className="question-description">
              {DisplyDeltaOrHtml(examSetDisplay?.question_description)}
            </p>

            {examSetDisplay?.choice?.length > 0 ? (
              <Fragment>
                <div className="choice">
                  {examSetDisplay?.choice?.map((choice, index) => (
                    <div className="choice-box">
                      <Radio
                        className="choice-box__radio"
                        checked={choice?.no === examSetDisplay?.userAnswer}
                        key={index}
                      />

                      <div className="choice-box__text">
                        {DisplyDeltaOrHtml(choice?.choice_description)}
                      </div>
                    </div>
                  ))}
                </div>
                <p>Correct Answer : {examSetDisplay?.userAnswer}</p>
              </Fragment>
            ) : (
              <TextFieldArea value={examSetDisplay?.userAnswer} />
            )}
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <ButtonIcon
            disabled={totalQuestion === 0 || displayQuestion === 0}
            onClick={() => setDisplayQuestion(displayQuestion - 1)}
          >
            <ArrowBack color="dark" /> Back
          </ButtonIcon>
          <ButtonIcon
            disabled={totalQuestion === displayQuestion + 1}
            onClick={() => setDisplayQuestion(displayQuestion + 1)}
          >
            Next <ArrowForward />
          </ButtonIcon>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupDetailCustomQuestion;
