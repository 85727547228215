import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Table from "components/table/Table";

import Grid from "@mui/material/Grid";

import {} from "redux/actions/campaign";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 100%;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 18px;
  }

  .level_container {
    display: flex;
    margin-bottom: 5px;

    .level_selection {
      margin-right: 5px;
    }
    .level_field {
      margin-right: 5px;
    }
    .level_btn {
      margin-top: 7px;
    }
  }

  .right {
    text-align: right;
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const PopupEditInvite = ({
  open,
  onClose,
  label,
  maxWidth = "xl",
  error,
  inviteedit,
  invitationTable,
  setInvitations,
  setInvitationTable,
}) => {
  const dispatch = useDispatch();

  const [from, setFrom] = useState(inviteedit);
  const [questionArr, setQuestionArr] = useState([]);

  useEffect(() => {
    setFrom(inviteedit);
  }, [inviteedit]);

  const handleFromChange = (key, value) => {
    setFrom({
      ...from,
      [key]: value,
    });
  };

  const handleSubmitEditInvite = () => {
    let newArr = invitationTable;
    let index = invitationTable.findIndex(it => it.id === inviteedit?.id);
    newArr[index].first_name = from.first_name
    newArr[index].last_name = from.last_name
    newArr[index].email = from.email

    setInvitations(newArr)
    setInvitationTable(newArr)
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Edit Email List</div>
            <div className="content-wrapper">
              <div className="form-wrapper">
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      label="Name"
                      value={from.first_name}
                      name="search_first_name"
                      onChange={(e) =>
                        handleFromChange("first_name", e.target.value)
                      }
                    />
                    <TextField
                      label="Last Name"
                      value={from.last_name}
                      name="search_last_name"
                      onChange={(e) =>
                        handleFromChange("last_name", e.target.value)
                      }
                    />
                    <TextField
                      label="Edit"
                      value={from.email}
                      name="search_email"
                      onChange={(e) =>
                        handleFromChange("email", e.target.value)
                      }
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => handleSubmitEditInvite()}>
            ยืนยัน
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditInvite;
