import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { checkValue } from "utils/common";
import formatValue from "utils/formatValue";

import InlineSpaceText from "components/form/InlineSpaceText";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";
import ButtonBlack from "components/form/ButtonBlack";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import Paper from "components/surfaces/Paper";
import Divider from "components/surfaces/Divider";

import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

import ReactHtmlParser from "react-html-parser";

import {
  getExamQuestionById,
  getExamChoiceDetailById,
  getExamImportQuestionById,
  getExamChoiceImportById,
} from "redux/actions/library";
import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";

import PopupEditExamQuestionChoice from "containers/popup/PopupEditExamQuestionChoice";
import PopupExamQuestionUpdate from "containers/popup/PopupExamQuestionUpdate";
import PopupExamQuestionStatusUpdate from "containers/popup/PopupExamQuestionStatusUpdate";
import DeltaToHtml from "components/form/DeltaToHtml";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
  }

  .question-img {
    max-width: 350px;
    padding: 10px 0;
    img {
      width: 100%;
    }
  }
  .question-item {
    display: flex;
    margin: 15px;
    .question-title {
      width: 200px;
      margin-left: 10px;
    }
    .question-detail {
      width: 100%;
    }
  }

  .section_question {
    text-indent: 50px;
  }
`;

const ExaminationDetail = () => {
  const { id, type } = useParams();
  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpenPopupExamQuestionUpdate, setIsOpenPopupExamQuestionUpdate] =
    useState(false);
  const [isOpenPopupEditQuestion, setIsOpenPopupEditQuestion] = useState(false);
  const [
    isOpenPopupExamQuestionStatusUpdate,
    setIsOpenPopupExamQuestionStatusUpdate,
  ] = useState(false);

  const { examQuestionDetail: examInfo } = useSelector(
    (state) => state.library
  );

  const [info, setInfo] = useState();

  useEffect(() => {
    getDetailQuestionExam();
  }, []);

  useEffect(() => {
    console.log("examInfo", examInfo);
    if (examInfo) {
      setInfo(examInfo);
    }
  }, [examInfo, type]);

  useEffect(() => {
    console.log("info", info);
  }, [info]);

  const getDetailQuestionExam = useCallback(() => {
    if (type === "import") {
      dispatch(getExamImportQuestionById(id));
    } else {
      dispatch(getExamQuestionById(id));
    }
  }, [id, type, dispatch]);

  const handleEditQuestion = (row) => {
    if (type === "import") {
      dispatch(getExamChoiceImportById(id));
      setIsOpenPopupEditQuestion(true);
    } else {
      dispatch(getExamChoiceDetailById(id)).then(() =>
        setIsOpenPopupEditQuestion(true)
      );
    }
  };

  const handleCloseEditQuestion = () => {
    if (type === "import") {
      dispatch(getExamImportQuestionById(id));
      setIsOpenPopupEditQuestion(false);
    } else {
      dispatch(getExamQuestionById(id)).then(() =>
        setIsOpenPopupEditQuestion(false)
      );
    }
  };

  const handleExamQuestionUpdate = () => {
    setIsOpenPopupExamQuestionUpdate(true);
  };

  const handleCloseExamQuestionUpdate = () => {
    if (type === "import") {
      dispatch(getExamImportQuestionById(id));
      setIsOpenPopupExamQuestionUpdate(false);
    } else {
      dispatch(getExamQuestionById(id)).then(() =>
        setIsOpenPopupExamQuestionUpdate(false)
      );
    }
  };

  const handleExamQuestionStatusUpdate = (row) => {
    setIsOpenPopupExamQuestionStatusUpdate(true);
  };

  const handleCloseExamQuestionStatusUpdate = () => {
    if (type === "import") {
      dispatch(getExamImportQuestionById(id));
      setIsOpenPopupExamQuestionStatusUpdate(false);
    } else {
      dispatch(getExamQuestionById(id)).then(() =>
        setIsOpenPopupExamQuestionStatusUpdate(false)
      );
    }
  };

  const DisplaySubjectName = (subjectId) => {
    if (subjectId) {
      return masterDataSubject
        ?.filter((it) => it.value === +subjectId)
        .map((it) => it.text);
    }
  };

  const DisplayJobName = (jobId) => {
    if (jobId) {
      return masterDataJob
        ?.filter((it) => it.value === jobId)
        .map((it) => it.text);
    }
  };

  const DisplyDeltaOrHtml = (value) => {
    const isDelta = value?.startsWith("[");
    if (isDelta) return <DeltaToHtml contents={value} />;
    return ReactHtmlParser(value);
  };

  return (
    <SidebarLayout title="Examination">
      {Boolean(isOpenPopupEditQuestion) && (
        <PopupEditExamQuestionChoice
          open
          onClose={() => handleCloseEditQuestion()}
          id={id}
          type={type}
        />
      )}
      {Boolean(isOpenPopupExamQuestionUpdate) && (
        <PopupExamQuestionUpdate
          open
          onClose={() => handleCloseExamQuestionUpdate()}
          id={id}
          dataEdit={info}
          type={type}
        />
      )}
      {Boolean(isOpenPopupExamQuestionStatusUpdate) && (
        <PopupExamQuestionStatusUpdate
          open
          onClose={() => handleCloseExamQuestionStatusUpdate()}
          id={id}
          dataEdit={info}
          type={type}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} className="headbar-panel-wrapper">
              <BackIcon
                className="back-icon"
                onClick={() => history.goBack()}
              />
              <span className="title">
                {info?.code ? info?.code : "ตัวอย่างข้อสอบ"}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid
                    item
                    spacing={1}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item alignItems="center" container xs={9}>
                      ข้อมูลเกี่ยวกับข้อสอบ
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText className="fix-height" label="วิชา">
                    <span>
                      {checkValue(DisplaySubjectName(info?.subject_id))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="หัวข้อ ">
                    <span>{checkValue(info?.topic_name)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="ระดับความยาก">
                    <span>{checkValue(info?.level)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="ตำแหน่งงาน">
                    <span>
                      {checkValue(DisplayJobName(info?.job_position_id))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    className="fix-height"
                    label="ชื่อผู้ออกข้อสอบ"
                  >
                    <span>{checkValue(info?.exam_owner)}</span>
                  </InlineSpaceText>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} direction="column" item>
                  <Grid
                    item
                    xs={12}
                    spacing={1}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item xs={9}>
                      สถานะข้อสอบ
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionStatusUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText
                    label="ชื่อผู้นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(info?.exam_owner)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(formatValue("Date", info?.createdAt))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="ชื่อผู้อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(info?.approve_by)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(formatValue("Date", info?.approve_date))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText label="สถานะข้อสอบ" className="fix-height ">
                    <span>{checkValue(info?.status)}</span>
                  </InlineSpaceText>
                  <br />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid item spacing={1} xs={12} container alignItems="center">
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <h2 style={{ marginRight: "5px" }}>โจทย์: </h2>
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleEditQuestion()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12} container>
                      <div>
                        <span className="section_question">
                          {DisplyDeltaOrHtml(
                            info?.exam_questions_choices?.[0]
                              ?.choice_description
                          )}
                        </span>

                        {info?.exam_questions_choices?.[0]?.choice_image?.[0]
                          ?.image_url !== "" && (
                          <div>
                            <br />
                            <img
                              className="question-img"
                              src={
                                info?.exam_questions_choices?.[0]
                                  ?.choice_image?.[0]?.image_url
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <div className="question-container">
                    {info?.exam_questions_choices?.map((it, index) => {
                      return (
                        index !== 0 && (
                          <div className="question-item" key={index}>
                            <div className="question-title">
                              คำตอบที่ {index}{" "}
                            </div>
                            <div className="question-detail">
                              <span>
                                {it?.choice_description !== "" &&
                                  DisplyDeltaOrHtml(it?.choice_description)}
                              </span>

                              {it?.choice_image?.[0]?.image_url !== "" && (
                                <div>
                                  <br />
                                  <img
                                    className="question-img"
                                    src={it?.choice_image?.[0]?.image_url}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      );
                    })}

                    <div className="question-item">
                      <div className="question-title">คำตอบที่ถูกต้อง</div>
                      <div className="question-detail">
                        <span>
                          {checkValue(
                            info?.exam_questions_choices
                              ?.filter((it) => it.is_answer)
                              .map((it) => it.choice_no)
                              .join(", ")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">Random คำตอบ</div>
                      <div className="question-detail">
                        <span>
                          {Boolean(info?.is_random_answer) ? "ใช่" : "ไม่ใช่"}
                        </span>
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">ชื่อผู้ออกข้อสอบ</div>
                      <div className="question-detail">
                        <span>{info?.exam_owner}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationDetail;
