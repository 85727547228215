import React from "react";
import styled from "styled-components";
import { Radio as RadioMui } from "@material-ui/core";

const Div = styled.div`
  display: inline;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  > label {
    font-size: 16px;
    margin-bottom: 13px;
  }
`;

const StyledRadio = styled(RadioMui)`
  padding: 11px;

  .MuiButton-label {
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
  }
`;

const Radio = ({ label, id, name, color = "primary", className, ...props }) => {
  return (
    <Div>
      <StyledRadio
        id={id}
        name={name}
        className={className}
        color={color}
        {...props}
      />
      {Boolean(label) && <label htmlFor={id}>{label}</label>}
    </Div>
  );
};

export default Radio;
