import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";

import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";

import { Grid } from "@material-ui/core";

import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TextEditor from "components/form/TextEditor";

import {
  updateExamQuestionChoice,
  updateExamChoiceImportById,
} from "redux/actions/library";
import RichTextEditor from "components/form/RichTextEditor";

const Div = styled.div`
  padding: 20px;
  width: 1200px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 550px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .invilid {
    color: red;
    font-size: 16px;
    margin-top: 10px;
  }
`;

const PopupEditExamQuestionChoice = ({
  open,
  onClose,
  maxWidth = "xl",
  id,
  type = "",
}) => {
  const dispatch = useDispatch();
  const { examQuestionChoiceDetail } = useSelector((state) => state.library);

  const [isRandom, setIsRandom] = useState(false);

  const [propositionFrm, setPropositionFrm] = useState([]);

  const [imgUrl, setImgUrl] = useState();
  const [indexImgUrl, setIndexImgUrl] = useState();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {}, []);

  const handleRandomChange = (value) => {
    setIsRandom(!value);
  };

  useEffect(() => {
    console.log("examQuestionChoiceDetail", examQuestionChoiceDetail);
    let newQuestion = examQuestionChoiceDetail?.map((item) => {
      return {
        choice_no: item.choice_no,
        choice_description: item?.choice_description?.startsWith("[")
          ? JSON.parse(item.choice_description)
          : item.choice_description,
        choice_type: item.choice_type,
        choice_image: item?.choice_image?.[0]?.image_url,
        is_answer: IsAnswerBool(item.is_answer),
        answer_point: 1,
      };
    });
    setPropositionFrm(newQuestion);
  }, [examQuestionChoiceDetail]);

  const IsAnswerBool = (isAnswer) => {
    if (typeof isAnswer !== "boolean") {
      return isAnswer == 0 ? false : true;
    }
    return isAnswer;
  };

  const submitEditExam = (e) => {
    e.preventDefault();
    console.log(propositionFrm);
    const choices = propositionFrm?.map((item, index) => {
      let obj = {
        choice_no: item.choice_no,
        choice_description: JSON.stringify(item.choice_description),
        choice_type: item.choice_type,
        choice_image: "",
        answer_point: 1,
        is_answer: item.is_answer ? 1 : 0,
      };

      if (type === "import") {
        obj.choice_image = [{ image_url: item.choice_image }];
      } else {
        obj.choice_image = item.choice_image;
      }

      return obj;
    });

    const images = propositionFrm?.map((item) => ({
      choice_no: item.choice_no,
      image_url: item.choice_image,
      image_order: 1,
    }));

    let obj = {
      is_random_answer: isRandom,
      choices,
      images,
    };

    console.log("edit", obj);

    if (type === "import") {
      dispatch(updateExamChoiceImportById(obj, id));
      onClose();
    } else {
      console.log("202", obj);
      dispatch(updateExamQuestionChoice(obj, id)).then(() => onClose());
    }
  };

  const handleCheckboxChange = (value, index) => {
    console.log(value);
    let newArr = [...propositionFrm];
    newArr[index].is_answer = !value;
    setPropositionFrm(newArr);
  };

  const setUploadImgUrl = (obj) => {
    let newArr = [...propositionFrm];
    newArr[obj?.index].choice_image = obj?.path;
    setPropositionFrm(newArr);
  };

  useEffect(() => {
    if (indexImgUrl) {
      setUploadImgUrl(indexImgUrl);
    }
  }, [indexImgUrl]);

  const handleSetDelta = (value, index) => {
    let newArr = [...propositionFrm];
    newArr[index].choice_description = value;
    setPropositionFrm(newArr);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <form onSubmit={submitEditExam}>
          <div className="form-wrapper">
            <div className="title">แก้ไขข้อสอบ</div>
          </div>
          <br />
          <div className="form-wrapper">
            {propositionFrm?.map((item, index) =>
              index === 0 ? (
                <div className="section-group" key={index}>
                  <div className="group-title">โจทย์</div>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <RichTextEditor
                        initValue={item.choice_description}
                        onRichTextBlur={(e) => handleSetDelta(e, index)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <UploadImage
                        minWidth={"290px"}
                        inputID={`answer${index}_img_url`}
                        index={index}
                        setURLImage={setImgUrl}
                        setIndexImage={setIndexImgUrl}
                        defaultImage={item?.choice_image}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div className="section-group" key={index}>
                  <div className="answer-container">
                    <div className="group-title">คำตอบที่ {index}</div>
                    <div className="answer-item">
                      <Checkbox
                        {...label}
                        isEdit={true}
                        checked={item.is_answer}
                        onChange={(e) =>
                          handleCheckboxChange(item.is_answer, index)
                        }
                      />{" "}
                      เลือกเป็นคำตอบที่ถูกต้อง
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <RichTextEditor
                        initValue={item.choice_description}
                        onRichTextBlur={(e) => handleSetDelta(e, index)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <UploadImage
                        minWidth={"290px"}
                        inputID={`answer${index}_img_url`}
                        index={index}
                        setURLImage={setImgUrl}
                        setIndexImage={setIndexImgUrl}
                        defaultImage={item?.choice_image}
                      />
                    </Grid>
                  </Grid>
                </div>
              )
            )}
            <div className="section-group">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  หมายเหตุ : การแก้ไขข้อสอบ จะต้องอนุมัติข้อสอบใหม่อีกครั้ง
                </Grid>
                <Grid item xs={6}>
                  <div className="text-right">
                    ต้องการ Random คำตอบหรือไม่{" "}
                    <Switch
                      {...label}
                      checked={isRandom}
                      onChange={() => {
                        handleRandomChange(isRandom);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="button-wrapper">
            <Button onClick={onClose} outlined>
              ยกเลิก{" "}
            </Button>
            <ButtonBlack type="submit">บันทึก</ButtonBlack>
          </div>
        </form>
      </Div>
    </BaseModal>
  );
};

export default PopupEditExamQuestionChoice;
