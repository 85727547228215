import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Grid from "@mui/material/Grid";
import { ArrowBack as BackIcon } from "@material-ui/icons";

import PopupProductQuotation from "containers/popup/PopupProductQuotation";

import {} from "redux/selectors";
import { addQuotation } from "redux/actions/order";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  button {
    width: 100%;
  }

  .btn_back {
    margin-right: 20px;
  }
`;

const orderTypeList = [
  { value: "", text: "เลือกประเภท Order" },
  { value: 1, text: "Order Type 1" },
  { value: 2, text: "Order Type 2" },
  { value: 3, text: "Order Type 3" },
];

const OrderTransactionCreateQuotation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { items, total, limit } = useSelector((state) => state.member);

  // const [memberList, setMemberList] = useState(items);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);

  const [quotationType, setQuotationType] = useState("");
  const [customerId, setCustomerId] = useState();
  const [projectNo, setProjectNo] = useState();
  const [programNo, setProgramNo] = useState();

  const [isOpenProductQuotation, setIsOpenProductQuotationModal] =
    useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {}, [products]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "รหัสสินค้า",
    },
    {
      field: "productName",
      headerName: "Product Name",
    },
    {
      field: "productCode",
      headerName: "Product Code",
    },
    { field: "total", headerName: "Price" },
    {
      field: "paymentTerm",
      headerName: "Payment Term",
      renderCell: IsNullValue,
    },
    { field: "productDetailTh", headerName: "Product Details" },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleProductQuotation = (row) => {
    setIsOpenProductQuotationModal(true);
  };

  const handleCloseProductQuotationPopup = () => {
    setIsOpenProductQuotationModal(false);
  };

  const handleDeleteItem = useCallback((row) => {
    setTimeout(() => {
      setProducts((prevRows) => prevRows.filter((it) => it.id !== row.id));
    });
  }, []);

  const submitCreateQuotation = (e) => {
    e.preventDefault();
    if (!validate()) {
      const totalPrice = products
        .map((it) => it.total)
        .reduce(
          (previousTota, currentTotal) =>
            Number(previousTota) + Number(currentTotal),
          0
        );

      let obj = {
        quotation_type: +quotationType,
        customer_id: +customerId,
        seller_id: 1,
        quotation_status: 1,
        remark: "",
        total_price: totalPrice,
        total_net: 0.07 * totalPrice + totalPrice,
        vat: 7,
        project_no: projectNo,
        program_no: programNo,
        quotation_detail: products.map((it) => {
          return {
            product_id: it.id,
            description: it.productDetailTh,
            quantity: 1,
            unit: 1,
            price_per_unit: it.total,
            status: 1,
            vat: 0,
          };
        }),
      };
      dispatch(addQuotation(obj)).then((res) => {
        const { payload } = res;
        if (payload) {
          window.open(`/quotation/${payload}`, "_blank");

          history.goBack();
        }
      });
    }
  };

  const handleQuotationTypeChange = (value) => {
    setQuotationType(value);
  };

  const validate = (
    fieldValues = {
      quotationType,
      customerId,
      projectNo,
      programNo,
    }
  ) => {
    let temp = { ...errors };
    console.log(fieldValues);
    if ("quotationType" in fieldValues)
      temp.quotationType =
        fieldValues.quotationType !== "" ? "" : "กรุณาเลือกประเภท Order";
    if ("customerId" in fieldValues)
      temp.customerId = fieldValues.customerId ? "" : "กรุณากรอกเลขที่สมาชิก";
    if ("projectNo" in fieldValues)
      temp.projectNo = fieldValues.projectNo ? "" : "กรุณากรอกเลข Project";
    if ("programNo" in fieldValues)
      temp.programNo = fieldValues.programNo ? "" : "กรุณากรอกเลขโครงการ";
    console.log(temp);
    setErrors({ ...temp });
    return quotationType === "" || customerId || projectNo || programNo;
  };

  return (
    <SidebarLayout title="Order Management">
      {Boolean(isOpenProductQuotation) && (
        <PopupProductQuotation
          maxWidth={"lg"}
          open
          onClose={handleCloseProductQuotationPopup}
          setProducts={setProducts}
          products={products}
        />
      )}
      <Div>
        <form onSubmit={submitCreateQuotation}>
          <div className="content-wrapper">
            <div className="form-wrapper">
              <div className="selection-group">
                <Grid container spacing={2}>
                  <Grid item xs>
                    <div className="title">
                      <BackIcon
                        className="btn_back"
                        onClick={() => history.goBack()}
                      />
                      Create Quotation
                    </div>
                  </Grid>
                  <Grid item>
                    <Button
                      className="add_product"
                      onClick={() => handleProductQuotation()}
                    >
                      เพิ่มรายการสินค้า
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="create_qoutation"
                      type="submit"
                      // onClick={() => submitCreateQuotation()}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <br />
              <div className="section-group">
                <div className="group-title"></div>
                <div className="group-field">
                  <Select
                    label="ประเภท Order"
                    className="fieldorderType"
                    defaultValue="Select Order Type"
                    value={quotationType}
                    items={orderTypeList}
                    onChange={(e) => handleQuotationTypeChange(e.target.value)}
                    errorText={errors.quotationType}
                    isError={errors.quotationType}
                  />
                  <TextField
                    label="เลขที่สมาชิก"
                    className="field_memberNo"
                    value={customerId}
                    onChange={(e) => setCustomerId(e.target.value)}
                    error={errors.customerId}
                  />
                  <TextField
                    label="เลข Project"
                    className="field_projectNo"
                    value={projectNo}
                    onChange={(e) => setProjectNo(e.target.value)}
                    error={errors.projectNo}
                  />
                  <TextField
                    label="เลขโครงการ"
                    className="field_projectNumber"
                    value={programNo}
                    onChange={(e) => setProgramNo(e.target.value)}
                    error={errors.programNo}
                  />
                </div>
              </div>
              <br />
            </div>
            <div className="table-wrapper">
              <Table
                columns={columns}
                rows={products}
                checkboxSelection={false}
                // rowCount={total}
                // pageSize={limit}
                page={page}
                onPageChange={handlePageChange}
                onDeleteRow={handleDeleteItem}
              />
            </div>
          </div>
        </form>
      </Div>
    </SidebarLayout>
  );
};

export default OrderTransactionCreateQuotation;
