import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";
import TextField from "components/form/TextField";
import Select from "components/form/Select";

import Date from "components/form/Date";

import { updateExamQuestionStatus } from "redux/actions/library";
import moment from "moment";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .wrapper-item {
    margin-bottom: 20px;
  }
`;

const examImporterList = [{ value: 1, text: "Admin 1" }];

const PopupExamQuestionStatusUpdate = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  id,
  type = "",
}) => {
  const dispatch = useDispatch();
  const [createdBy, setCreatedBy] = useState("");
  const [createdAt, setCreatedAt] = useState(null);

  useEffect(() => {
    setCreatedBy(dataEdit?.createdBy);
    setCreatedAt(dataEdit?.createdAt);
  }, [dataEdit]);

  const handleCreateByChange = (value) => {
    setCreatedBy(value);
  };

  const handleCreateDateChange = (value) => {
    setCreatedAt(value);
  };

  const submitQuestionStatusUpdate = () => {
    let obj = {
      createdBy: createdBy,
      createdAt: moment(createdAt).format("YYYY-MM-DD"),
    };
    if (type === "import") {
    } else {
      dispatch(updateExamQuestionStatus(obj, id)).then(() => onClose());
    }
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">แก้ไขข้อมูลเกี่ยวกับข้อสอบ</div>
            <p>
              <strong>รหัสข้อสอบ {dataEdit?.code}</strong>
            </p>
            <div className="wrapper-item">
              <Select
                label="ผู้นำเข้าข้อสอบ"
                items={examImporterList}
                value={createdBy}
                defaultValue="ผู้นำเข้าข้อสอบ"
                className="field-createby"
                onChange={(e) => handleCreateByChange(e.target.value)}
              />
            </div>
            <div className="wrapper-item">
              <Date
                label="วันที่นำเข้าข้อสอบ"
                value={createdAt}
                disableFuture
                onChange={(date) => handleCreateDateChange(date)}
              />
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก{" "}
          </Button>
          <ButtonBlack onClick={submitQuestionStatusUpdate}>
            แก้ไขข้อมูล{" "}
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupExamQuestionStatusUpdate;
