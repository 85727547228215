import React, { useState, useEffect } from "react";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { css } from "@emotion/react";
import "quill/dist/quill.snow.css";

export default function DeltaToHtml({ contents }) {
  const [html, setHtml] = useState();

  useEffect(() => {
    if (contents) {
      let deltaObj = JSON.parse(contents);
      var converter = new QuillDeltaToHtmlConverter(deltaObj, {});
      converter.renderCustomWith(function (customOp, contextOp) {
        if (customOp.insert.type === "mathjax") {
          let val = customOp.insert.value;
          return `${val}`;
        } else {
          return "Unmanaged custom blot!";
        }
      });
      var htmlConverter = converter.convert();
      setHtml(htmlConverter);
    }
  }, [contents]);

  return (
    <div>
      <div className="ql-editor" style={{ padding: "0" }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}


