import React from "react";
import styled from "styled-components";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { TextField, InputAdornment } from "@material-ui/core";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const Div = styled.div`
  display: inline-flex;
  position: relative;
  min-width: 0;
  flex-direction: column;
  width: 100%;
  vertical-align: top;
  > label {
    font-size: 16px;
    margin-bottom: 13px;
  }
`;

const StyledDate = styled(DatePicker)`
  width: 100%;
  .MuiInputBase-root {
    height: 47px;
    border: 1px solid #e8ecef;
    background: #fff;
    border-radius: 4px;
    font-size: 16px;
    color: #080808;
    padding: 14px 16px;

    .MuiInputAdornment-positionStart {
      margin-right: 0;

      .MuiSvgIcon-root {
        width: 16px;
        color: #b6b8c1;
      }
    }

    .MuiInputBase-input {
      padding: 7px 0 7px;
    }

    &:before,
    &:after {
      visibility: hidden;
    }
  }
`;

const CustomTextField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

const Date = ({ label, ...props }) => {
  return (
    <Div>
      {Boolean(label) && <label>{label}</label>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledDate
          variant="inline"
          format="dd-MM-yyyy"
          disableToolbar
          autoOk
          TextFieldComponent={CustomTextField}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </Div>
  );
};

export default Date;
