import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Grid, Tabs, Tab, Box } from '@material-ui/core'
import Table from 'components/table/Table'
import StatusCell from 'components/table/StatusCell'
import TabPanel from 'components/surfaces/TabPanel'
import PopupStatusCell from 'containers/popup/PopupStatusCell'

const Div = styled.div`
  padding: 20px;

  .status-cell {
    :hover {
      cursor: pointer;
    }
  }
`

const mockDataBeauty = [
  {
    id: '01-01-03',
    domain: 'ความเสี่ยงจากริ้วรอยของวัย',
    status: '3',
    issue: 'issue',
    date: '-',
  },
  {
    id: '-',
    domain: 'ความสามารถต้านแดวแดดผิวด่างดำ',
    status: '-',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '01-02-02',
    domain: 'ความสามารถต้านอนุมูลอิสระ',
    status: '2',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '01-03-02',
    domain: 'ความสามารถต้านการเกิดสิว',
    status: '1',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '02-03-02',
    domain: 'ความสามารถต่อต้านเคราติน',
    status: '1',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '03-03-02',
    domain: 'ความสามารถต้านผ้ากระ',
    status: '1',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '04-03-02',
    domain: 'ความไวต่อกลิ่นเหงื่อ',
    status: '1',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '05-03-02',
    domain: 'โรคผื่นภูมิแพ้ผิวหนัง',
    status: '2',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '06-03-02',
    domain: 'การเกิดรอยแผลเป็น',
    status: '2',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '07-03-02',
    domain: 'ความสามารถต่อต้านริ้วรอยของวัย',
    status: '2',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '08-03-02',
    domain: 'โรคด่างขาว',
    status: '2',
    issue: 'issue',
    date: '22/03/2021',
  },
  {
    id: '11-03-02',
    domain: 'ความสามารถในการซ่อมแซมความยืดหยุ่นของผิว',
    status: '1',
    issue: 'issue',
    date: '22/03/2021',
  },
]
const TabDNAStatus = () => {
  const [tabActive, setTabActive] = useState(0)
  const { items, total, limit, uploadProcess } = useSelector((state) => state.snp)
  const [page, setPage] = useState(0)
  const [selectedId, setSelectedId] = useState([])
  const [isStatusPopup, setStatusPopup] = useState(false)
  const [statusDisplayPopup, setStatusDisplayPopup] = useState('')

  const handleChangeTab = (event, newValue) => {
    setTabActive(newValue)
  }

  const handlePageChange = (nextPage) => {
    setPage(nextPage)
  }

  const handleDeleteItem = ({ id }) => {}

  const handleOpenPopup = (status) => {
    setStatusPopup(true)
    setStatusDisplayPopup(status)
  }

  const StatusCellWrapper = ({ value }) => {
    let status = ''
    status = value === '-' ? 'Messing Data' : value

    return (
      <StatusCell className="status-cell" onClick={() => handleOpenPopup(status)} type={value}>
        {status}
      </StatusCell>
    )
  }

  const IssueCellWrapper = ({ value }) => {
    return <div className="issue-wrapper"> {value}</div>
  }

  const IssueDateWrapper = ({ value }) => {
    return <div className="date-issue-wrapper">{value}</div>
  }

  const columnsBeauty = [
    { field: 'id', headerName: 'Result-ID' },
    { field: 'domain', headerName: 'Domain' },
    { field: 'status', headerName: 'Status', renderCell: StatusCellWrapper },
    { field: 'issue', headerName: 'Issue', renderCell: IssueCellWrapper },
    { field: 'date', headerName: 'Issue Date', renderCell: IssueDateWrapper },
  ]

  return (
    <Div>
      {Boolean(isStatusPopup) && (
        <PopupStatusCell
          open
          onClose={() => setStatusPopup(false)}
          status={statusDisplayPopup}
          maxWidth={'xs'}
        />
      )}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Beauty & Skin" />
          <Tab label="Behavior & Personality" />
          <Tab label="Cancel Risk" />
          <Tab label="Drug Response" />
          <Tab label="Genetic Disorders" />
          <Tab label="Health " />
          <Tab label="Nutrition " />
          <Tab label="Origin " />
          <Tab label="Performance & Exercise " />
          <Tab label="Sleep & Stress " />
        </Tabs>
      </Box>
      <TabPanel value={tabActive} index={0}>
        <div className="table-wrapper">
          <Table
            columns={columnsBeauty}
            rows={mockDataBeauty}
            rowCount={total}
            pageSize={limit}
            page={page}
            checkboxSelection={false}
            onPageChange={handlePageChange}
            onDeleteRow={handleDeleteItem}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabActive} index={1}>
        Tab{' '}
      </TabPanel>
    </Div>
  )
}

export default TabDNAStatus
