import React from "react";
import styled from "styled-components";
import {
  Accordion as CollapseWrapper,
  AccordionSummary as HeaderCollapse,
  AccordionDetails as ContentCollapse,
} from "@mui/material";
import { ExpandMoreOutlined as ExpandIcon } from "@material-ui/icons";

const StyleCollapse = styled(CollapseWrapper)`
  padding: 0;
  box-shadow: none !important;
`;
const StyleHeader = styled(HeaderCollapse)`
  padding: 0 !important;
  justify-content: flex-start !important;
  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
`;
const StyleContent = styled(ContentCollapse)`
  padding: 0;
  background-color: inherit;
`;

const Collapse = ({
  className,
  classNameHeader,
  classNameContent,
  disabled = false,
  id,
  label,
  children,
  ...props
}) => {
  return (
    <StyleCollapse
      disabled={disabled}
      disablegutters
      className={className}
      square={true}
      {...props}
    >
      <StyleHeader
        expandIcon={<ExpandIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className={classNameHeader}
      >
        {label}
      </StyleHeader>
      <StyleContent disableGutters className={classNameContent}>
        {children}
      </StyleContent>
    </StyleCollapse>
  );
};

export default Collapse;
