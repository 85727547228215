import { Chart as ChartJS, registerables } from "chart.js";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   PointElement,
//   LineElement,
// } from "chart.js";

import { Chart } from "react-chartjs-2";
import React, { useRef, useEffect } from "react";

ChartJS.register(...registerables);

const scalesX = [
  [0, 10],
  [11, 20],
  [21, 30],
  [31, 40],
  [41, 50],
  [51, 60],
  [61, 70],
  [71, 80],
  [81, 90],
  [91, 100],
];

const customBorder = {
  id: "customBorder",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      chartArea: { top, right, bottom, left, width, height },
    } = chart;
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.moveTo(left, top);
    ctx.lineTo(right - 2, top);
    ctx.lineTo(right - 2, bottom);
    ctx.lineTo(left, bottom);
    ctx.lineTo(left, top);
    ctx.stroke();
  },
};

const customTitleTop = {
  id: "customTitleTop",
  afterDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.textAlign = "center";
    ctx.font = "bold 22px Arial";
    ctx.fillText("Histrogram Distribution", chart.width / 2, 45);
    ctx.restore();
  },
};

ChartJS.register(customTitleTop, customBorder);

const options = {
  elements: {
    bar: {
      borderWidth: 2,
    },
  },

  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "Histrogram Distribution",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      drawBorder: true,
      offset: true,

      title: {
        display: false,
        text: "# of Candidates",
      },
      ticks: {
        color: "black",
        stepSize: 1,
      },
    },
    x: {
      title: {
        display: false,
        text: "Histrogram คะแนนของผู้เข้าสอบ",
      },
      ticks: {
        color: "black",
        stepSize: 1,
      },
    },
  },
};

// const options = {
//   responsive: true,
//   maintainAspectRatio: false,
//   plugins: {
//     tooltip: {
//       enabled: false,
//     },
//     legend: {
//       display: false,
//     },
//     title: {
//       display: false,
//       text: "Distribution of Candidates Scores",
//     },
//   },
//   scales: {
//     y: {
//       beingAtZero: false,
//       drawBorder: true,
//       ticks: {
//         display: false,
//         color: "black",
//         stepSize: 1,
//       },

//       title: {
//         display: true,
//         text: "# of Candidates",
//         font: {
//           size: 24,
//           weight: "bold",
//         },
//         color: "black",
//       },
//       position: "left",
//       grid: {
//         display: false,
//       },
//     },
//     x: {
//       offset: true,
//       beingAtZero: false,
//       drawBorder: true,
//       title: {
//         display: true,
//         text: "Raw Score",
//         font: {
//           size: 24,
//           weight: "bold",
//         },
//         color: "black",
//       },
//       position: "bottom",
//       grid: {
//         display: false,
//       },
//     },
//   },
// };

const testData = [
  {
    email: "tadswan@2bsimple.com",
    first_name: "ทัศวรรณ",
    last_name: "จรรยารักษ์สกุล",
    user_id: "bd830090118ab90f8d67e8ff2d5796",
    id: 28,
    start_time: "2022-09-20 18:24:42",
    end_time: "2022-09-20 18:26:44",
    time_in: "00:02:02",
    status: "done",
    logic: 5,
    total_score: 5,
    percent: 33,
    campaign_uid: "fb57ab16086284d37c68",
  },
  {
    email: "test@gmail.com",
    first_name: "ต้นน้ำ",
    last_name: "ลำธาร",
    user_id: "63ca50872dcafff7dad1f5052faf9e",
    id: 29,
    start_time: "2022-09-20 18:47:30",
    end_time: "2022-09-20 18:49:10",
    time_in: "00:01:40",
    status: "done",
    logic: 4,
    total_score: 4,
    percent: 26,
    campaign_uid: "fb57ab16086284d37c68",
  },
  {
    email: "",
    first_name: "nop",
    last_name: "nop",
    user_id: "cdddc08ec0ba9d8584c45e1d69d212",
    id: 30,
    start_time: "2022-09-20 19:19:40",
    end_time: "2022-09-20 19:21:15",
    time_in: "00:01:35",
    status: "done",
    logic: 0,
    total_score: 0,
    percent: 0,
    campaign_uid: "fb57ab16086284d37c68",
  },
  {
    email: "",
    first_name: "lk",
    last_name: "kz",
    user_id: "d19cc1fa1603156a7fdce65f7e8699",
    id: 31,
    start_time: "2022-09-20 19:20:55",
    end_time: "2022-09-20 19:21:10",
    time_in: "00:00:15",
    status: "done",
    logic: 0,
    total_score: 0,
    percent: 0,
    campaign_uid: "fb57ab16086284d37c68",
  },
  {
    email: "",
    first_name: "mop",
    last_name: "mop",
    user_id: "fd6ab74abf206acd24970de567ae8f",
    id: 32,
    start_time: "2022-09-20 19:31:51",
    end_time: "2022-09-20 19:33:08",
    time_in: "00:01:17",
    status: "done",
    logic: 4,
    total_score: 4,
    percent: 26,
    campaign_uid: "fb57ab16086284d37c68",
  },
  {
    email: "",
    first_name: "ทดสอบ",
    last_name: "จำกัด",
    user_id: "acc28ad6c1f7157a296eb0a1b35a4c",
    id: 59,
    start_time: "2022-10-03 16:08:29",
    end_time: "2022-10-03 16:09:40",
    time_in: "00:01:11",
    status: "done",
    logic: 7,
    total_score: 7,
    percent: 46,
    campaign_uid: "fb57ab16086284d37c68",
  },
];

const BarChart = ({ dataResult }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;

    triggerTooltip(chart);
  }, []);

  const triggerTooltip = (chart) => {
    const tooltip = chart?.tooltip;

    if (!tooltip) {
      return;
    }

    if (tooltip.getActiveElements().length > 0) {
      tooltip.setActiveElements([], { x: 0, y: 0 });
    } else {
      const { chartArea } = chart;

      tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: 2,
          },
          {
            datasetIndex: 1,
            index: 2,
          },
        ],
        {
          x: (chartArea.left + chartArea.right) / 2,
          y: (chartArea.top + chartArea.bottom) / 2,
        }
      );
    }

    chart.update();
  };

  const calculateBetween = (dataSet, num) => {
    let min = dataSet[0];
    let max = dataSet[1];
    return num >= min && num <= max ? true : false;
  };

  let score = (dataResult || [])?.map((it) => it.percent);

  const dataSet = (scalesX || []).map(
    (it) =>
      (score || [])
        .map((it2) => calculateBetween(it, it2))
        .filter((check) => check == true).length
  );

  const data = {
    labels: (scalesX || [])?.map((it) => it?.join("-")),
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#1D1E24",
        borderWidth: 2,
        fill: false,
        data: dataResult ? dataSet : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        type: "bar",
        label: "Dataset 2",
        backgroundColor: "#6272AA",
        data: dataResult ? dataSet : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  return <Chart ref={chartRef} options={options} type="bar" data={data} />;

  // return (
  //   <div className="container">
  //     <div className="chart-container">
  //       <div>
  //         <Bar options={options} data={data} />
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default BarChart;
