import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkNewTabCell from "components/table/RouterLinkNewTabCell";
import Grid from "@mui/material/Grid";
import { ArrowBack as BackIcon } from "@material-ui/icons";

import PopupProductQuotation from "containers/popup/PopupProductQuotation";

import { createReceipt, getReceiptById } from "redux/actions/order";
import { checkValue } from "../../utils/common";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  button {
    width: 100%;
  }
`;

const orderTypeList = [
  { value: 1, text: "Order Type 1" },
  { value: 2, text: "Order Type 2" },
  { value: 3, text: "Order Type 3" },
];

const OrderTransactionGenerateReceipt = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { receiptById } = useSelector((state) => state.order);
  const [page, setPage] = useState(0);
  const [paymentTerm, setPaymentTerm] = useState();

  useEffect(() => {
    dispatch(getReceiptById(id));
  }, [id]);

  useEffect(() => {
    console.log("receiptById", receiptById.invoice);
    if (receiptById?.invoice) {
      let tempArr = receiptById?.invoice.map((item) => item.payment_term);
      console.log("tempArr", tempArr);
      if (tempArr?.length > 0) {
        let total = tempArr.reduce((prev, next) => prev + next);
        setPaymentTerm(total);
      }
    }
  }, [receiptById]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const QuatationNoCell = () => {
    return receiptById?.quotation_no;
  };

  const VatCell = () => {
    return receiptById?.vat;
  };

  const ProveOfPaymentCell = ({ value }) => {
    return <RouterLinkNewTabCell to={value}>ภาพประกอบ</RouterLinkNewTabCell>;
  };

  const CreateReceiptCell = ({ value, row }) => {
    return (
      <Button
        className="button-invite"
        disabled={paymentTerm !== 100}
        onClick={() => submitCreate(row?.id)}
      >
        ออกใบรับเงิน
      </Button>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
    },
    {
      field: "invoice_no",
      headerName: "Invoice Number",
      width: 200,
    },
    {
      field: "quotation",
      headerName: "Quotation Number",
      renderCell: QuatationNoCell,
      width: 200,
    },
    {
      field: "payment_term",
      headerName: "Payment Term",
      width: 200,
    },
    {
      field: "total_price",
      headerName: "Amount",
      width: 200,
    },
    {
      field: "total_tax",
      headerName: " Tax",
      width: 200,
    },
    {
      field: "vat",
      headerName: " Vat",
      renderCell: VatCell,
      width: 200,
    },
    {
      field: "slip_image_url",
      headerName: "หลักฐานการโอนเงิน",
      renderCell: ProveOfPaymentCell,
      width: 200,
    },
    {
      field: "",
      headerName: "ออกใบรับเงิน",
      width: 200,
      renderCell: CreateReceiptCell,
    },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const submitCreate = (id) => {
    let obj = {
      invoice_id: id,
    };
    dispatch(createReceipt(obj)).then((res) => {
      console.log(res);
      const { payload } = res;
      if (payload) {
        window.open(`/receipt/${payload}`, "_blank");
        history.goBack();
      }
    });
  };

  return (
    <SidebarLayout title="Order Management">
      <Div>
        {/* <form onSubmit={submitCreate}> */}
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="selection-group">
              <Grid container spacing={2}>
                <Grid item xs>
                  <div className="title">
                    <BackIcon
                      className="back-icon"
                      onClick={() => history.goBack()}
                    />
                    Generate Receipt
                  </div>
                </Grid>

                {/* <Grid item>
                    <Button
                      className="button-invite"
                      disabled={paymentTerm !== 100}
                      type="submit"
                    >
                      Create Delivery Note
                    </Button>
                  </Grid> */}
              </Grid>
            </div>
            <div className="section-group">
              <div className="group-title"></div>
              <div className="group-field">
                <Select
                  label="ประเภท Order"
                  name="search_orderType"
                  defaultValue="Select Order Type"
                  value={checkValue(receiptById?.quotation_type)}
                  disabled
                  items={orderTypeList}
                />
                <TextField
                  label="เลขที่สมาชิก"
                  value={checkValue(receiptById?.quotation_no)}
                  name="search_memberNo"
                  disabled
                />
                <TextField
                  label="เลข Project"
                  value={checkValue(receiptById?.project_no)}
                  name="search_projectNo"
                  disabled
                />
                <TextField
                  label="เลขโครงการ"
                  value={checkValue(receiptById?.program_no)}
                  name="search_projectNumber"
                  disabled
                />
              </div>
            </div>
            <div className="section-group">
              <div className="group-title"></div>
              <div className="group-field">
                <TextField
                  label="Payment Term"
                  // name="payment_term"
                  placeholder={"Payment Term (1-100)%"}
                  disabled
                  value={paymentTerm}
                />
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={receiptById?.invoice}
              checkboxSelection={false}
              // rowCount={total}
              // pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        {/* </form> */}
      </Div>
    </SidebarLayout>
  );
};

export default OrderTransactionGenerateReceipt;
