import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Date from "components/form/Date";

import Grid from "@mui/material/Grid";

import { updateCampaignById } from "redux/actions/campaign";
import {
  selectMasterJob,
  selectMasterJobLevel,
  selectMasterOrganization,
} from "../../redux/selectors";
import Autocomplete from "@mui/material/Autocomplete";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 100%;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 18px;
  }

  .level_container {
    display: flex;
    margin-bottom: 5px;

    .level_selection {
      margin-right: 5px;
    }
    .level_field {
      margin-right: 5px;
    }
    .level_btn {
      margin-top: 7px;
    }
  }

  .right {
    text-align: right;
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const campaignTypList = [
  { value: "evaluate", text: "Evaluate" },
  { value: "recruitment", text: "Recruitment" },
];

const campaignTargetList = [
  { value: "internal", text: "Internal" },
  { value: "external", text: "External" },
];

const PopupEditAssessment = ({
  open,
  onClose,
  label,
  maxWidth = "xl",
  error,
  assessmentedit,
  setInvitations,
  index,
}) => {
  const dispatch = useDispatch();
  const { campaignDetail } = useSelector((state) => state.campaign);
  const [from, setFrom] = useState(campaignDetail);
  const [questionArr, setQuestionArr] = useState([]);
  const masterDataJob = useSelector(selectMasterJob);
  const masterDataJobLevel = useSelector(selectMasterJobLevel);
  const masterDataOrganization = useSelector(selectMasterOrganization);
  const [dataJob, setDataJob] = useState();

  useEffect(() => {
    setFrom(campaignDetail);
  }, [campaignDetail]);

  useEffect(() => {
    let dataJob = masterDataJob.map((it) => ({ label: it.text, id: it.value }));
    setDataJob(dataJob);
  }, [masterDataJob]);

  const handleSubmitEditAssessmet = () => {
    console.log("from", from);
    let newFrom = [from];

    let newArrayOfObj = newFrom?.map(
      ({
        customQuestionList: custom_question_data,
        examSetList: exam_set_data,
        ...rest
      }) => ({
        custom_question_data,
        exam_set_data,
        ...rest,
      })
    );

    let custom_question_data = newArrayOfObj?.map(
      (it) => it.custom_question_data
    );

    let new_custom_question_data = custom_question_data[0]?.map(
      ({ exam_campaigns_custom_questions_choices: choice, ...rest }) => ({
        choice,
        ...rest,
      })
    );

    newArrayOfObj[0].custom_question_data = new_custom_question_data;

    let obj = newArrayOfObj?.map((it) => ({
      campaign_name: it.campaign_name,
      duration_time: it.duration,
      campaign_type: it.campaign_type,
      use_in: it.use_in,
      campaign_detail: it.campaign_detail,
      category: it.category,
      is_publish: it.is_publish,
      campaign_start_date: it.campaign_start_date,
      campaign_end_date: it.campaign_end_date,
      job_role_id: it.job_role_id,
      job_role_level: it.job_role_level,
      vdo_url: it.vdo_url,
      vdo_embed: it.vdo_embed,
      is_show_vdo: it.is_show_vdo,
      is_snapshot: it.is_snapshot,
      extra_time: it.extra_time,
      exam_set_data: it.exam_set_data,
      custom_question_data: it.custom_question_data,
    }))[0];

    dispatch(updateCampaignById(obj, campaignDetail?.id));
    onClose();
  };

  const handleChangeFrom = (key, value) => {
    console.log(value);
    setFrom({
      ...from,
      [key]: value,
    });
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Edit Assessment</div>
            <div className="content-wrapper">
              <div className="form-wrapper">
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      label="Campaign Name *"
                      name="search_campaign_name"
                      value={from?.campaign_name}
                      onChange={(e) =>
                        handleChangeFrom("campaign_name", e.target.value)
                      }
                    />
                    <Select
                      label={"Organization *"}
                      value={from?.organization_id}
                      items={masterDataOrganization}
                      onChange={(e) =>
                        handleChangeFrom("organization_id", e.target.value)
                      }
                    />
                    <Autocomplete
                      disablePortal
                      options={dataJob}
                      sx={{ width: 300 }}
                      value={
                        dataJob?.find((it) => it.id == from?.job_role_id)?.label
                      }
                      onChange={(e, value) =>
                        handleChangeFrom("job_role_id", value?.id)
                      }
                      id="field-job"
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                          <span>{option.label}</span>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Job Position *" />
                      )}
                    />
                    <Select
                      label={"Campaign Type *"}
                      value={from?.campaign_type}
                      items={campaignTypList}
                      onChange={(e) =>
                        handleChangeFrom("campaign_type", e.target.value)
                      }
                    />
                    <Date
                      label={"Date Start *"}
                      value={from?.campaign_start_date}
                      onChange={(date) =>
                        handleChangeFrom("campaign_start_date", date)
                      }
                    />
                    <Date
                      label={"Date End *"}
                      value={from?.campaign_end_date}
                      onChange={(date) =>
                        handleChangeFrom("campaign_end_date", date)
                      }
                    />
                    <Select
                      label={"Job Level *"}
                      value={from?.job_role_level}
                      items={masterDataJobLevel}
                      onChange={(e) =>
                        handleChangeFrom("job_role_level", +e.target.value)
                      }
                    />
                    <Select
                      label={"Campaign Target *"}
                      value={from?.use_in}
                      items={campaignTargetList}
                      onChange={(e) =>
                        handleChangeFrom("use_in", e.target.value)
                      }
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => handleSubmitEditAssessmet()}>
            ยืนยัน
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditAssessment;
