import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Download } from "assets/icons/download.svg";
import { ReactComponent as Printer } from "assets/icons/printer.svg";
// Component
import Paper from "components/surfaces/Paper";
import Button from "components/form/Button";

import { useReactToPrint } from "react-to-print";
import { Invoice } from "./Invoice";

import { getInvoiceById } from "redux/actions/order";

const Wrapper = styled.div`
  margin: 3% auto 0;
  box-sizing: border-box;
  background: #e5e5e5;
  width: 60vw;
  max-width: 100%;
  height: auto;

  .header_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 30px;
    .btn_download,
    .btn_print {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      font-weight: bold;
      font-size: 24px;
      color: #e0172a;
      width: 210px;
      background-color: #fff;
      border: 2px solid #e0172a;
      border-radius: 4px;
      column-gap: 10px;
      box-shadow: none;
      text-decoration: initial;
      path {
        fill: #e0172a;
      }
      :hover {
        cursor: pointer;
      }
      a {
        text-decoration: initial;
        color: #e0172a;
      }
    }
  }

  .btn_container {
    width: 800px;
    margin: 0 auto;

    .next_btn {
      width: 100%;
      color: #ffffff;
      background: #e0172a;
      padding: 8px;
      margin: 0 0 32px 0;
      border-radius: 4px;
      height: 48px;
      font-size: 24px;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        opacity: 0.7;
        &:hover {
          cursor: initial;
        }
      }
    }

    .back_btn {
      width: 100%;
      color: #020202;
      background: inherit;
      font-size: 20px;
      text-decoration: underline;
      padding: 8px;
      border-radius: 4px;
    }
  }
`;

const WrapperInvoice = () => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const refPdf = React.createRef();

  const { invoiceById } = useSelector((state) => state.order);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getInvoiceById(id));
  }, [id]);

  return (
    <Wrapper>
      <Paper>
        <div className="header_btn">
          <Button className="btn_print" onClick={handlePrint}>
            <Printer />
            Print
          </Button>
        </div>

        <div>
          <Invoice ref={componentRef} info={invoiceById} />
        </div>
      </Paper>
    </Wrapper>
  );
};

export default WrapperInvoice;
