import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Moment from "moment";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";

import Date from "components/form/Date";

import { getExamCampaignByDate } from "redux/actions/report";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 10px;
  }
`;

const PopupBatchReport = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {}, []);

  const handleStartDateBatchReportChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateBatchReportChange = (date) => {
    setEndDate(date);
  };

  const submitGenerateReport = () => {
    let obj = {
      startDate: Moment(startDate).format("YYYY-MM-DD"),
      endDate: Moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getExamCampaignByDate(obj));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Batch Report</div>
            <div className="mb-3">
              {" "}
              <Date
                placeholder="วันที่เริ่มต้น"
                value={startDate}
                disableFuture
                onChange={(date) => handleStartDateBatchReportChange(date)}
              />
            </div>
            <div>
              {" "}
              <Date
                placeholder="วันที่สิ้นสุด"
                value={endDate}
                disableFuture
                onChange={(date) => handleEndDateBatchReportChange(date)}
              />
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={submitGenerateReport}>Generate </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupBatchReport;
