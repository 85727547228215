import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Date from "components/form/Date";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import UploadImage from "../UploadImage";

import { editMemberInfo } from "redux/actions/member";

import { selectMasterDataBank } from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const PopupEditInformationMember = ({
  open,
  onClose,
  maxWidth,
  memberType,
}) => {
  const dispatch = useDispatch();
  const { member_id } = useParams();
  const masterDataBank = useSelector(selectMasterDataBank);
  const { memberData } = useSelector((state) => state.member);

  const [firstName, setFirstName] = useState(memberData?.first_name);
  const [lastName, setLastName] = useState(memberData?.last_name);
  const [email, setEmail] = useState(memberData?.email);
  const [phone, setPhone] = useState(memberData?.phone);
  const [bankName, setBankName] = useState(memberData?.bank_account);
  const [bankAccountNumber, setBankAccountNumber] = useState(
    memberData?.bank_account_no
  );
  const [bankAccountName, setBankAccountName] = useState(
    memberData?.bank_account_name
  );
  const [idCardImgUrl, setIdCardImgUrl] = useState(
    memberData?.verify_document?.[0]?.document_url
  );
  const [idBankAccountImgUrl, setIdBankAccountImgUrl] = useState(
    memberData?.verify_document?.[1]?.document_url
  );

  const submitEditMember = () => {
    const obj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      bank_account: bankName, // bank_name
      bank_account_no: bankAccountNumber,
      bank_account_name: bankAccountName,
      id_card_img_url: idCardImgUrl,
      bank_account_img_url: idBankAccountImgUrl,
      member_type: memberType,
      id_card_verify_document_id: memberData?.verify_document?.[0]?.id,
      bank_account_verify_document_id: memberData?.verify_document?.[1]?.id,
    };
    dispatch(editMemberInfo(+member_id, obj));
    onClose();
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Edit Member Information</div>

          <p className="form-topic">ข้อมูล</p>
          <InlineSpaceText label="ชื่อจริง">
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="field-firstName"
            />
          </InlineSpaceText>
          <InlineSpaceText label="นามสกุล">
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="field-lastName"
            />
          </InlineSpaceText>
          <InlineSpaceText label="Email">
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="field-email"
            />
          </InlineSpaceText>
          <InlineSpaceText label="เบอร์โทร">
            <TextField
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="field-phone"
            />
          </InlineSpaceText>

          <p className="form-topic">ธนาคาร</p>
          <InlineSpaceText label="ชื่อธนาคาร">
            <Select
              items={masterDataBank}
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              className="field-bankName"
              id="field-bankName"
              defaultValue="เลือกธนาคาร"
            />
          </InlineSpaceText>
          <InlineSpaceText label="เลขที่บัญชีธนาคาร">
            <TextField
              value={bankAccountNumber}
              onChange={(e) => setBankAccountNumber(e.target.value)}
              className="field-bankAccountNumber"
            />
          </InlineSpaceText>
          <InlineSpaceText label="ชื่อบัญชี">
            <TextField
              value={bankAccountName}
              onChange={(e) => setBankAccountName(e.target.value)}
              className="field-bankAccountName"
              id="field-bankAccountName"
            />
          </InlineSpaceText>
          <UploadImage
            label={"อัพโหลดรูปบัตรประชาชน"}
            inputID={"id_card_img_url"}
            defaultImage={idCardImgUrl}
            setURLImage={setIdCardImgUrl}
          />
          <UploadImage
            inputID={"bank_account_img_url"}
            label={"อัพโหลดรูปสมุดบัญชีธนาคาร"}
            defaultImage={idBankAccountImgUrl}
            setURLImage={setIdBankAccountImgUrl}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancer{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitEditMember()}>
            Edit
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditInformationMember;
