import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const StyledFormControl = styled.div`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  textarea {
    padding: 14px 16px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 4px;
    border-color: #e8ecef;
    outline: none;
    max-height: 300px;
  }

  textarea:disabled {
    background-color: #eeeeee;
    color: #939393;
  }

  .MuiStepIcon-root.Mui-active,
  .MuiStepIcon-root.Mui-completed {
    color: black !important;
  }

  
  .MuiStepIcon-root {
    width: 2em;
    height: 2em;
  }

  .MuiStepConnector-root {
    top: 25px;
    left: calc(-50% + 24px);
    right: calc(50% + 24px);
  }
`;

const StepBar = ({ steps = [], activeStep = 0, ...props }) => {
  return (
    <StyledFormControl>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </StyledFormControl>
  );
};

export default StepBar;
